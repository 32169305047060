import update from 'immutability-helper';

import { AUTH_REQUEST_PENDING, AUTH_REQUEST_FAILED, SIGN_IN, LOG_OUT, VALIDATE_TOKEN } from './types';

const defaultState = {
  user: {},
  authError: null,
  isRequestPending: false,
  isAuthenticated: false,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_REQUEST_PENDING:
      return update(state, {
        isRequestPending: { $set: true },
      });
    case AUTH_REQUEST_FAILED:
      return update(state, {
        authError: { $set: action.message },
        isRequestPending: { $set: false },
      });
    case SIGN_IN:
      return update(state, {
        user: { $set: action.payload.user },
        isAuthenticated: { $set: true },
        isRequestPending: { $set: false },
      });
    case VALIDATE_TOKEN:
      return update(state, {
        user: { $set: action.payload.user },
        isAuthenticated: { $set: true },
        isRequestPending: { $set: false },
      });
    case LOG_OUT:
      return {};
    default:
      return state;
  }
};

export default auth;
