import React from 'react';
import {Select, TextField, Radios} from 'mui-rff';
import {MenuItem} from '@material-ui/core';

export const createFormFields = (values, isUpdate) => {
  return [
    {
      size: 6,
      isCopy: true,
      name: 'platformName',
      field: (
        <TextField
          id='platformName'
          name='platformName'
          label='Client name'
          fullWidth
          // disabled={isUpdate}
          required
        />
      ),
    },
    {
      size: 6,
      isCopy: true,
      name: 'wlid',
      field: (
        <TextField
          id='wlid'
          name='wlid'
          label='WLID'
          fullWidth
          disabled={isUpdate}
          required
        />
      ),
    },
    {
      size: 6,
      isCopy: true,
      name: 'firstName',
      field: (
        <TextField
          id='firstName'
          name='firstName'
          label='First Name'
          fullWidth
        />
      ),
    },
    {
      size: 6,
      isCopy: true,
      name: 'lastName',
      field: (
        <TextField
          id='lastName'
          name='lastName'
          label='Last Name'
          fullWidth
        />
      ),
    },
    {
      size: 6,
      isCopy: true,
      name: 'email',
      field: (
        <TextField
          id='email'
          name='email'
          label='Email'
          fullWidth
        />
      ),
    },
    {
      size: 6,
      isCopy: true,
      name: 'domain',
      field: (
        <TextField
          id='domain'
          name='domain'
          label='Admin Domain'
          fullWidth
          placeholder='ui-{TITLE}.ubidex.bid'
        />
      ),
    },
    {
      size: 6,
      isCopy: true,
      name: 'appDomain',
      field: (
        <TextField
          id='appDomain'
          name='appDomain'
          label='App Domain'
          fullWidth
          placeholder='partners.ubidex.bid'
        />
      ),
    },
    {
      size: 6,
      isCopy: true,
      name: 'traffDomain',
      field: (
        <TextField
          id='traffDomain'
          name='traffDomain'
          label='Traff Domain'
          fullWidth
          placeholder='http://'
        />
      ),
    },
    // {
    //   size: 6,
    //   isCopy: true,
    //   name: 'trackDomain',
    //   field: (
    //     <TextField
    //       id='trackDomain'
    //       name='trackDomain'
    //       label='Track Domain'
    //       fullWidth
    //       placeholder='https://'
    //     />
    //   ),
    // },
    {
      size: 12,
      field: <TextField name='notes' multiline label='Notes' margin='none' />,
    },
    {
      size: 6,
      field: (
        <Select
          name='type'
          label='Type'
          formControlProps={{margin: 'none'}}
          multiple
        >
          <MenuItem value='Advertiser'>Advertiser</MenuItem>
          <MenuItem value='Dsp'>Dsp</MenuItem>
          <MenuItem value='AdExchange'>AdExchange</MenuItem>
        </Select>
      ),
    },
    {
      size: 6,
      field: (
        <Select
          name='adType'
          label='Ad Type'
          formControlProps={{margin: 'none'}}
          multiple
        >
          <MenuItem value='Banner'>Banner</MenuItem>
          <MenuItem value='Video'>Video</MenuItem>
          <MenuItem value='Native'>Native</MenuItem>
          <MenuItem value='Audio'>Audio</MenuItem>
          <MenuItem value='Push'>Push</MenuItem>
          <MenuItem value='Pop'>Pop</MenuItem>
          <MenuItem value='Calendar'>Calendar</MenuItem>
        </Select>
      ),
    },
    {
      size: 12,
      field: (isUpdate ?
        <Radios
          label='Status'
          name='status'
          disabled={!isUpdate}
          data={[
            {label: 'Active', value: 'active'},
            {label: 'Paused', value: 'paused'},
            {label: 'Removed', value: 'removed'},
          ]}
        /> : null
      ),
    },
    // {
    //   size: 12,
    //   field: <CustomSwitcher />,
    // },
  ];
};
