import {Button, Link} from '@material-ui/core';
import clsx from 'clsx';
import classNames from 'classnames';
import * as React from 'react';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@mui/material/Tooltip';

export const getColumns = (props) => [
  {
    field: 'wlid',
    headerName: 'WLID',
    type: 'number',
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value);
      return `${valueFormatted}`;
    },
  },
  {
    field: 'platformName',
    headerName: 'Client Name',
    sortable: false,
    width: 150,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Link style={{cursor: 'pointer', textDecoration: 'none'}}>{params.value}</Link>;
    },
  },
  {
    field: 'isStopBiddingUS',
    headerName: 'US',
    sortable: false,
    width: 120,
    renderCell: (params) => (
      <Tooltip title={params.row.stopReasonUS}>
        <FiberManualRecordIcon style={{color: params.value === false ? 'green' : 'orange'}} />
      </Tooltip>),
  },
  {
    field: 'isStopBiddingEU',
    headerName: 'EU',
    sortable: false,
    width: 120,
    renderCell: (params) => (
      <Tooltip title={params.row.stopReasonEU}>
        <FiberManualRecordIcon style={{color: params.value === false ? 'green' : 'orange'}} />
      </Tooltip>),
  },
  {
    field: 'alertDate',
    headerName: 'Payment Date',
    sortable: false,
    width: 200,
    valueFormatter: (params) => {
      const valueFormatted = params.value ? params.value.slice(0, 10) : '-';
      return `${valueFormatted}`;
    },
  },
  {
    field: 'firstName',
    headerName: 'Manager',
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return <span>{params.row.firstName} {params.row.lastName}</span>;
    },
  },
  {field: 'email', headerName: 'Email', sortable: false, width: 200},
  {field: 'type', headerName: 'Type', sortable: false, width: 200},
  {field: 'adType', headerName: 'Ad Type', sortable: false, width: 200},
  {
    field: 'status',
    headerName: 'Status',
    disableClickEventBubbling: true,
    renderCell: (params) => {
      const classStatus = clsx('status', {
        new: params.value === 'new',
        active: params.value === 'active',
        paused: params.value === 'paused',
        removed: params.value === 'removed',
      });
      return (
        <span className={classStatus}>{params.value}</span>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Registered',
    width: 200,
    valueFormatter: (params) => {
      const valueFormatted = params.value ? params.value.slice(0, 10) : params.value;
      return `${valueFormatted}`;
    },
  },
  {
    sortable: false,
    disableClickEventBubbling: true,
    width: 130,
    renderCell: (params) => {
      const classSignIn = classNames({
        disabled: !params.row.domain,
      });
      return (
        <a
          className={classSignIn}
          style={{textDecoration: 'none'}}
          href={`${__REST_SERVER__}/v1/auth/sign-to?d=${params.row.domain}`}
          target='_blank' rel='noreferrer'
        >
          <Button size='small' variant='contained' color='primary'>{`Sign In as ${params.row.wlid}`}</Button>
        </a>
      );
    },
  },
];
