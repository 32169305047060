import axios from 'axios';

export const reloadNginx = async (obj) => {
  try {
    await axios.post('/v1/reload/ui-conf', obj);
    return true;
  } catch (e) {
    return false;
  }
};

export const reloadTrackNginx = async (obj) => {
  return await axios.post('/v1/reload/traff-conf', obj).catch((err) => ({status: 400, message: err.response.data}));
};

export const reloadCDNNginx = async (obj) => {
  return await axios.post('/v1/reload/cdn-conf', obj).catch((err) => ({status: 400, message: err.response.data}));
};
