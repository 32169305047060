import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';

const Loader = ({isPending, children}) => {
    return (
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={isPending}
      >
        <CircularProgress style={{'color': '#3f51b5'}} />
      </Backdrop>
    );
};

export default Loader;
