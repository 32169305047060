import React, {Fragment, useEffect, useState} from 'react';
import {Container, makeStyles} from '@material-ui/core';
//
import TransactionHistoryTable from './TransactionHistoryTable';
import {getWlIdDropdownValues, getPublisherDropdownValues, getEndpointDropdownValues} from 'actions/reports';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    colCell: {
        flex: 1,
        minWidth: '1px !important',
        maxWidth: 'none !important',
    },
    flexGridItem: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '30px',
    },
}));

const TopUpRequests = (props) => {
    // hooks
    const classes = useStyles();

    const [wlidOptions, setWlidOptions] = useState([]);
    const [ugwPublisherOptions, setUgwPublisherOptions] = useState([]);
    const [ugwEndpointOptions, setUgwEndpointOptions] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const wlids = await getWlIdDropdownValues();
        setWlidOptions(wlids);

        const publishers = await getPublisherDropdownValues();
        setUgwPublisherOptions(publishers);

        const endpoints = await getEndpointDropdownValues();
        setUgwEndpointOptions(endpoints);
      };

      fetchData();
    }, []);

    return (
      <Fragment>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='95%' className={classes.container}>
          <TransactionHistoryTable
            clientOptionsData={wlidOptions}
            publisherOptionsData={ugwPublisherOptions}
            endpointOptionsData={ugwEndpointOptions}
          />
        </Container>
      </Fragment>
    );
};
export default TopUpRequests;
