import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import {Grid, Paper} from '@material-ui/core';
import {DataGrid} from '@mui/x-data-grid';
//
import FilterPanel from './FilterPanel';
import Title from 'components/common/Title';
import Loader from 'components/common/Loader';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import {DoneIcon} from 'components/common/icons';
import {UGW_ENDPOINT_TRANSACTIONS_HISTORY_COLUMN_DEFS} from 'utils/tables/columns/transactionsHistory';
import {getUgwPublisherEndpointTransactions, createPayment} from 'actions/index';
import useStyles from '../transactions.styles';

function QuickSearchToolbar(props) {
    if (!props.isRowsSelected) {
        return null;
    }

    return (
      <Grid item xs={4} md={6} lg={6} align='left'>
        <div className='actions_cover active'>
          <div className='actions_cover-item' onClick={props.onOpenDialog} data-id='ACTIVE'>
            <DoneIcon style={{marginBottom: '-7px'}} />
            <span>Create Payment</span>
          </div>
        </div>
      </Grid>
    );
}

const TransactionHistoryTable = (props) => {
    // hooks
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [isPending, setPending] = useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);

    useEffect(() => {
      const fetchTransactionsData = async () => {
        const transactionList = await getUgwPublisherEndpointTransactions();
        setTransactions(transactionList);
      };

      fetchTransactionsData();
    }, []);

    const applyFilters = async (filters) => {
      const results = await getUgwPublisherEndpointTransactions(filters);
      setTransactions(results);
    };

    const onToggleOpenDialog = (e) => setShowDialog(!showDialog);

    const handleConfirm = async (e) => {
      setShowDialog(false);
      setPending(true);

      await createPayment(selectedRows);
      window.location.href = '/transactions/top-up-requests';
    };

    return (
      <Paper>
        <div style={{width: '100%', marginTop: '15px', marginBottom: '15px'}}>
          <Box sx={{pt: 2, px: 2}}>
            <Title>Top Up Requests History</Title>
          </Box>
          <Box>
            <FilterPanel
              clientOptionsData={props.clientOptionsData}
              publisherOptionsData={props.publisherOptionsData}
              endpointOptionsData={props.endpointOptionsData}
              applyFilters={applyFilters}
            />
          </Box>
          <Box sx={{pt: 2, px: 2, paddingBottom: '15px'}}>
            <Loader isPending={isPending} />
            <ConfirmationDialog
              title='Create Payment'
              contentText='By clicking "Approve", you confirm that you have received money from the Client and initiate the payment process to the Publisher. Are you sure?'
              isOpen={showDialog}
              handleConfirm={handleConfirm}
              handleClose={onToggleOpenDialog}
            />

            <DataGrid
              rows={transactions}
              columns={UGW_ENDPOINT_TRANSACTIONS_HISTORY_COLUMN_DEFS}
              className={`${classes.root}`}
              sx={{
                'boxShadow': 2,
                'border': 0.5,
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
              }}
              components={{Toolbar: QuickSearchToolbar}}
              componentsProps={{
                toolbar: {
                  onOpenDialog: onToggleOpenDialog,
                  isRowsSelected: selectedRows.length > 0,
                },
              }}
              onRowSelectionModelChange={(ids) => {
                const selectedIds = new Set(ids);
                const selectedRows = transactions.filter((row) =>
                  selectedIds.has(row.id),
                ).map((row) => row.transactionNumber);

                setSelectedRows(selectedRows);
              }}
              checkboxSelection
            />
          </Box>
        </div>
      </Paper>
    );
};

export default TransactionHistoryTable;
