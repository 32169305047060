export const PAYMENT_ALERT = 'Please top up your balance till 10th of {CURRENT_MONTH}. Don\'t lose your access to the platform.';
export const ACTIVE = 'active';
export const NEW = 'new';
export const REMOVED = 'removed';
export const SSP_LOGO_EXTENSIONS = ['.jpeg', '.jpg', '.png'];
export const MAX_FILE_SIZE = 1500 * 1024;

export const ALL = 'ALL';
export const ADM = 'adm';
export const HTML = 'html';

export const ADM_FORMAT = {
  html: 'html',
  xml: 'xml',
  vast_3_0: 'vast 3.0',
  json: 'json',
};
