import update from 'immutability-helper';

import {LOAD_PLATFORM_SETTINGS, UPDATE_UNASSIGNED_BALANCE} from './types';

const defaultState = {
  balance: {
    amount: 0,
    availableBalance: 0,
  },
  isRequestPending: false,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_PLATFORM_SETTINGS: {
      return update(state, {
        balance: {
          $set: {
            amount: action.payload.balance.amount,
            availableBalance: action.payload.balance.availableUnassignedBalance,
          },
        },
        isRequestPending: {$set: false},
      });
    }
    case UPDATE_UNASSIGNED_BALANCE:

      return update(state, {
        balance: {
          $set: {
            amount: state.balance.amount + action.payload.deposit,
            availableBalance: state.balance.availableBalance + action.payload.deposit,
          },
        },
        isRequestPending: {$set: false},
      });
    default:
      return state;
  }
};

export default auth;
