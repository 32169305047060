import React from 'react';
import {Form} from 'react-final-form';
import DateFnsUtils from '@date-io/date-fns';
import {Grid, MenuItem} from '@material-ui/core';
import {DatePicker, Select} from 'mui-rff';
import Box from '@mui/material/Box';
import _ from 'lodash';
//
import ButtonSubmit from 'components/common/ButtonSubmit';

const dateFns = new DateFnsUtils();

const initial = {
  startDate: dateFns.format(dateFns.startOfMonth(new Date()), 'yyyy-MM-dd'),
  endDate: dateFns.format(new Date(), 'yyyy-MM-dd'),
};

const FilterPanel = (props) => {
    const onSubmit = async (values) => {
      const filtersProps = {
        ...values,
        ...(values.startDate && !_.isString(values.startDate) ? {startDate: dateFns.format(values.startDate, 'yyyy-MM-dd')} : {}),
        ...(values.endDate && !_.isString(values.endDate) ? {endDate: dateFns.format(values.endDate, 'yyyy-MM-dd')} : {}),
      };

      await props.applyFilters(filtersProps);
    };

    return (
      <div>
        <Form
          initialValues={initial}
          onSubmit={(values) => onSubmit(values)}
          render={({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container alignItems='flex-start' spacing={5} style={{marginLeft: '5px', marginTop: '15px', marginBottom: '10px'}}>
                <Grid item xs={2}>
                  <DatePicker
                    name='startDate'
                    margin='none'
                    label='Start Date'
                    dateFunsUtils={DateFnsUtils}
                    format='yyyy-MM-dd'
                  />
                </Grid>
                <Grid item xs={2}>
                  <DatePicker
                    defaultValue={dateFns.format(new Date(), 'yyyy-MM-dd')}
                    name='endDate'
                    margin='none'
                    label='End Date'
                    dateFunsUtils={DateFnsUtils}
                    format='yyyy-MM-dd'
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name='ugwId'
                    label='Publisher'
                    multiple
                  >
                    {
                      props.clientOptionsData.map((item, index) => (
                        <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                    ))
                  }
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name='status'
                    label='Status'
                    multiple
                  >
                    <MenuItem value='PENDING'>Pending</MenuItem>
                    <MenuItem value='APPROVED'>Approved</MenuItem>
                    <MenuItem value='COMPLETED'>Completed</MenuItem>
                  </Select>
                </Grid>
                <Grid item style={{marginTop: 16}}>
                  <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                    <ButtonSubmit
                      type='submit'
                      name='Apply'
                      onSubmit={handleSubmit}
                      isPending={submitting}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
    );
};

export default FilterPanel;
