export const normalizeBoolean = (value) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

export const normalizeNumber = (value) => value && Number(value) ? Number(value) : value;
