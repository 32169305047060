import React, {Fragment, useState, useEffect} from 'react';
import {Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
//
import {MenuIcon, ChevronLeftIcon, ExitToAppIcon} from '../common/icons';
import Dashboard from '../dashboard';
import EditPage from '../form/whitelabel';
import Publishers from '../../containers/Publishers';
import CreatePublisherPage from '../../containers/Publishers/CreatePublisher';
import Endpoints from '../../containers/Endpoints';
import EndpointEditPage from 'components/form/endpoint';
import ReportsPage from 'components/reports';
// Transactions
import ClientTransactions from '../../containers/Transactions/ClientTransactions';
import PublisherPayments from '../../containers/Transactions/PublisherPayments';
import TopUpRequests from '../../containers/Transactions/TopUpRequests';
import SidebarTmp from '../../layouts/Sidebar';
import {useLocalStorage} from '../../hooks';
import {logout} from 'actions/index';
import Platform from 'components/platform';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();

  const platformBalance = useSelector((state) => state.platform.balance);

  const [isOpen, setToggleSideBar] = useLocalStorage('openSideBar', true);
  const [user, setCurrentUser] = useState({balance: {amount: 0, availableBalance: 0}});

  useEffect(() => {
    if (props.user) {
      setCurrentUser(props.user);
    }
  }, [props.user]);

  const handleLogout = () => {
    logout(props.history);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='absolute' className={clsx(classes.appBar, isOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={() => setToggleSideBar(true)}
            className={clsx(classes.menuButton, isOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
            ADMIN PANEL
          </Typography>
          <Typography component='h3' color='inherit'>
            Balance: {platformBalance.amount}$
          </Typography>
          <Typography component='h3' color='inherit' style={{marginLeft: '20px'}}>
            Unassigned Balance: {platformBalance.availableBalance}
          </Typography>
          <IconButton color='inherit' onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
        }}
        open={isOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setToggleSideBar(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <SidebarTmp user={user} />
      </Drawer>
      <main className={classes.content}>
        {
          user.role === 'ADMIN' && (
            <Fragment>
              <Route path='/dashboard' component={() => <Dashboard {...props} />} />
              <Route path='/cpanel' component={() => <Platform {...props} />} />
              <Route
                path='/create'
                component={(rprops) => (
                  <EditPage
                    {...rprops}
                    whitelabels={props.whitelabels}
                    resetWhitelabels={props.resetWhitelabels}
                    isUpdate={false}
                  />
                    )}
              />
              <Route
                path='/update/:id'
                component={(rprops) => (
                  <EditPage
                    {...rprops}
                    whitelabels={props.whitelabels}
                    resetWhitelabels={props.resetWhitelabels}
                    isUpdate
                  />
                    )}
              />
            </Fragment>
            )
        }
        <Route exact path='/publishers' component={() => <Publishers {...props} />} />
        <Route
          exact
          path='/publishers/create'
          component={(rprops) => (
            <CreatePublisherPage
              {...rprops}
              isUpdate={false}
            />
          )}
        />
        <Route
          exact
          path='/publishers/:id/edit'
          component={(rprops) => (
            <CreatePublisherPage
              {...rprops}
              isUpdate
            />
          )}
        />
        <Route exact path='/endpoints' component={() => <Endpoints {...props} />} />
        <Route
          path='/endpoints/create'
          exact
          component={(rprops) => (
            <EndpointEditPage
              {...rprops}
              whitelabels={props.whitelabels}
              resetWhitelabels={props.resetWhitelabels}
              isUpdate={false}
            />
          )}
        />
        <Route
          path='/endpoints/:id/edit'
          component={(rprops) => (
            <EndpointEditPage
              {...rprops}
              whitelabels={props.whitelabels}
              resetWhitelabels={props.resetWhitelabels}
              isUpdate
            />
        )}
        />
        <Route path='/reports' component={() => <ReportsPage {...props} />} />
        <Route path='/transactions/clients' component={() => <ClientTransactions {...props} />} />
        <Route path='/transactions/publishers' component={() => <PublisherPayments {...props} />} />
        <Route path='/transactions/top-up-requests' component={() => <TopUpRequests {...props} />} />
      </main>
    </div>
  );
}
