import React, {useEffect, useState, Fragment} from 'react';
import {Field, Form} from 'react-final-form';
import {Select} from 'mui-rff';
import {Box} from '@mui/material';
import {Grid, makeStyles, MenuItem, Paper, IconButton, Typography} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useSnackbar} from 'notistack';
//
import {createFormFields, IntegrationTypeRadioControls, PaymentTypeRadioControls} from './fields';
import {getInitialForm} from 'components/form/publisher/utils';
import ButtonSubmit from 'components/common/ButtonSubmit';
import FilesDropZone from 'components/common/DropZone';
import Loader from 'components/common/Loader';
import {createPublisher, getPublisherByID, updatePublisher} from 'actions/index';
import {getEndpointDropdownValues} from 'actions/reports';
import {MAX_FILE_SIZE, SSP_LOGO_EXTENSIONS} from '../../../../constants';
import {normalizeBoolean} from 'utils/normalizers';

const useStyles = makeStyles((theme) => ({
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));


export default function General(props) {
  const {isUpdate, match, history} = props;

  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();

  const [generalInfo, setGeneralInfo] = useState(null);
  const [endpoints, setEndpoints] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (isUpdate) {
        const data = await getPublisherByID(match.params.id);

        setGeneralInfo(data);
      } else {
        setGeneralInfo(getInitialForm());
      }

      const endpointList = await getEndpointDropdownValues();
      setEndpoints(endpointList);
    };

    fetchData();
  }, []);

  const onSubmit = async (values, props) => {
    const {isUpdate} = props;

    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(values));

      for (const file of files) {
        formData.append(file.id, file);
      }

      let publisherId;

      if (isUpdate) {
        await updatePublisher(values.id, formData);

        publisherId = values.id;

        history.push('/publishers');
      } else {
        const {pub} = await createPublisher(values.id, formData);

        publisherId = pub.id;

        history.push(`/publishers/${publisherId}/edit`);
      }
      enqueueSnackbar(`(${publisherId}) Publisher ${isUpdate ? 'updated' : 'created'} successfully`, {variant: 'success'});
    } catch (e) {
      enqueueSnackbar('Server error!', {variant: 'error'});
    }
  };

  const handleUploadFile = (files) => {
    const confirm = () => {
      const uploadedFiles = [];

      files.forEach((file) => {
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
          file.width = img.width;
          file.height = img.height;
          file.resolution = `${img.width}x${img.height}`;
          file.createdAt = new Date();

          uploadedFiles.push(file);

          if (files.length === uploadedFiles.length) {
            bulkUploadCreatives(uploadedFiles, 'image');
          }
        };

        img.src = _URL.createObjectURL(file);
      });
    };

    if (files.length === 1) {
      confirm();
    }
  };

  const bulkUploadCreatives = (data, type) => {
    const uploadedFiles = Array.isArray(data) ? data : [data];
    const uploaded = [];

    for (const file of uploadedFiles) {
      file.id = type;
      uploaded.push(file);
    }

    setFiles(uploaded);
  };

  return (
    <Fragment>
      {
        generalInfo ?
          <Form
            onSubmit={(values) => onSubmit(values, {...props})}
            initialValues={generalInfo}
            render={({handleSubmit, form, submitting, pristine, values}) => {
              const state = form.getState().values;

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Loader isPending={submitting} />
                  <Paper style={{padding: 16}}>
                    <Grid item xs={6} className={classes.flexGridItem}>
                      <FilesDropZone
                        extensions={SSP_LOGO_EXTENSIONS}
                        maxFileSize={MAX_FILE_SIZE}
                        onUploadFile={handleUploadFile}
                        isMulti={false}
                        allowUploadThroughArchive
                        form={form}
                        state={state}
                      />
                    </Grid>

                    <Grid container alignItems='flex-start' spacing={2}>
                      {
                        values && createFormFields(values, isUpdate).map((item, idx) => (
                          <Grid item xs={item.size} key={idx} className={classes.flexGridItem}>
                            {item.field}
                            {
                              item.isCopy && item.name ?
                                <CopyToClipboard
                                  text={values[item.name]}
                                  onCopy={() => enqueueSnackbar('Copied!', {variant: 'success'})}
                                >
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    aria-label='copy'
                                  >
                                    <FileCopyOutlinedIcon fontSize='small' />
                                  </IconButton>
                                </CopyToClipboard> : null
                            }
                          </Grid>
                        ))
                      }
                    </Grid>

                    <Field component={IntegrationTypeRadioControls} name='isGateway' />

                    {
                      normalizeBoolean(values.isGateway) && <Field component={PaymentTypeRadioControls} name='paymentType' />
                    }

                  </Paper>

                  <br />

                  {
                    normalizeBoolean(values.isGateway) && (
                      <Fragment>
                        <Typography component='label' variant='h6'>
                          Integration
                        </Typography>

                        <Paper style={{padding: 16}}>
                          <Grid xs={6} alignItems='flex-start' className={classes.flexGridItem}>
                            <Select
                              name='endpoints'
                              label='Endpoints'
                              formControlProps={{margin: 'none'}}
                              multiple
                            >
                              {
                                endpoints.map((item, index) => (
                                  <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                                ))
                              }
                            </Select>
                          </Grid>
                        </Paper>
                      </Fragment>
                    )
                  }

                  <Grid item style={{marginTop: 16}}>
                    <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                      <ButtonSubmit
                        name='save'
                        onSubmit={handleSubmit}
                        isPending={submitting}
                        type='submit'
                      />
                    </Box>
                  </Grid>
                </form>
              );
            }}
          /> : null
      }
    </Fragment>
  );
}
