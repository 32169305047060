import React, {useEffect, useState, Fragment} from 'react';
import {Field, Form} from 'react-final-form';
import {Checkboxes, Select, TextField} from 'mui-rff';
import Box from '@mui/material/Box';
import {Grid, makeStyles, MenuItem, Paper, IconButton, Button, Typography} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useSnackbar} from 'notistack';
//
import {createFormFields} from './fields';
import {getInitialForm} from 'components/form/endpoint/utils';
import Loader from 'components/common/Loader';
import ButtonSubmit from 'components/common/ButtonSubmit';
import {
  RadioAdapterIntegrationType,
  RadioAdapterRtbAdType,
  RadioAdapterProtocol,
  RadioAdapterAdvancedOptions,
  RadioAdapterAdType,
  RadioAdapterTrafficType,
  RadioAdapterAuctionType,
  RadioAdapterPaymentsWith,
  RadioAdapterHtmlFormat,
  RadioAdapterGateway,
  RadioAdapterValidationDefault,
  RadioAdapterMismatchParams,
  RadioAdapterImpDuplicates,
  RadioAdapterFilterMaskedIP,
  RadioAdapterLookupUserId,
  RadioAdapterSkipCreativeSize,
  RadioAdapterClickDuplicates,
  RadioAdapterClickWithoutImpRule,
  RadioAdapterAdmFormat,
  RadioAdapterCapPubUID,
  RadioAdapterAllowClickMismatch,
} from 'components/form/endpoint/tabs/common';
import {createEndpoint, getEndpointByID, updateEndpoint} from 'actions/index';
import {getPublisherDropdownValues} from 'actions/reports';
import {hashids, stringToBoolean, getStartDate, getEndDate} from 'utils/common';
import {composeValidators, required, isNumber, maxValue, minValue, isUrl} from 'utils/validate';
import config from '../../../../config';

const useStyles = makeStyles((theme) => ({
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const minDiscrepancyValue = minValue(0);
const maxDiscrepancyValue = maxValue(100);

export default function General(props) {
  const {isUpdate, match, history} = props;

  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();

  const [generalInfo, setGeneralInfo] = useState(null);
  const [publishers, setPublishers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (isUpdate) {
        const data = await getEndpointByID(match.params.id);

        const start = getStartDate();
        const end = getEndDate();

        data.gatewayApiUrl = `${config.gatewayApiDomain}/report/gateway?pId=${data.id}&key=${data.apiKey}&startDate=${start}&endDate=${end}`;
        setGeneralInfo(data);
      } else {
        setGeneralInfo(getInitialForm('oRTB', 'BANNER'));
      }

      const publisherList = await getPublisherDropdownValues();
      setPublishers(publisherList);
    };
    fetchData();
  }, []);

  const onChangeLocation = (form) => {
    if (!isUpdate) {
      return;
    }
    const state = form.getState().values;
    let domain = config.usaTraffDomain;
    let route;
    let macros = '';
    const hashIds = hashids.encode([1, state && state.id]);

    if (state && state.location === 'Europe') {
     domain = config.euTraffDomain;
    }

    if (state && state.integrationType === 'RTB' && state && state.protocolType === 'oRTB') {
      switch (state && state.adType) {
        case 'BANNER': {
          route = 'banner';
          break;
        }
        case 'NATIVE': {
          route = 'native';
          break;
        }
        case 'VIDEO': {
          route = 'video';
          break;
        }
        case 'AUDIO': {
          route = 'bid';
          break;
        }
        case 'PUSH': {
          route = 'pushrtb';
          break;
        }
        case 'POP': {
          route = 'poprtb';
          break;
        }
        default: route = 'banner';
      }
    } else if (state && state.integrationType === 'RTB' && state && state.protocolType === 'XML') {
      switch (state && state.adType) {
        case 'POP': {
          route = 'pop';
          break;
        }
        case 'PUSH': {
          route = 'push';
          break;
        }
        case 'CALENDAR': {
          route = 'calendar';
          break;
        }
        default: route = 'pop';
      }
      macros = `&content_type=json&subid={SUB_ID}&query={KEYWORD}&ip={IP}&ua={USER_AGENT}&ref={REFERRER}&lang={BROWSER_LANGUAGE}&sid={SUBSCRIBER_ID}&sdate={SUBSCRIBER_DATE}&limit={LIMIT}&country={COUNTRY}`;
    } else if (state && state.integrationType === 'DIRECT') {
      switch (state && state.adType) {
        case 'POP': {
          route = 'pop';
          break;
        }
        default: route = 'pop';
      }
      macros = '&content_type=direct&subid={SUB_ID}&fallback={FALLBACK}';
    }

    form.change('bidderUrl', `${domain}/${route}?pid=${state && state.id}&auth=${hashIds}${macros}`);
  };

  const onSubmit = async (values, props) => {
    const {isUpdate} = props;
    let endpointId;

    try {
      if (isUpdate) {
        await updateEndpoint(values);

        endpointId = values.id;

        history.push('/endpoints');
      } else {
        const {endpoint} = await createEndpoint(values);

        endpointId = endpoint.id;

        history.push(`/endpoints/${endpoint.id}/edit`);
      }
      enqueueSnackbar(`(${endpointId}) Endpoint ${isUpdate ? 'updated' : 'created'} successfully`, {variant: 'success'});
    } catch (e) {
      enqueueSnackbar('Server error!', {variant: 'error'});
    }
  };

  const resetForm = (form) => {
    const state = form.getState().values;
    form.initialize(getInitialForm(state && state.protocolType, state && state.adType));
  };

  return (
    <Fragment>
      {
        generalInfo ?
          <Form
            onSubmit={(values) => onSubmit(values, {...props})}
            initialValues={generalInfo}
            render={({handleSubmit, form, submitting, pristine, values}) => {
              const state = form.getState().values;
              onChangeLocation(form);

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Typography component='label' variant='h6'>
                    Endpoint info
                  </Typography>

                  <Loader isPending={submitting} />

                  <Paper style={{padding: 16}}>
                    <Grid item xs={4} className={classes.flexGridItem}>
                      <Select
                        name='publisherId'
                        label='Publisher *'
                        multiple={false}
                        fieldProps={{validate: required}}
                        disabled={isUpdate}
                      >
                        {
                          publishers.map((item, index) => (
                            <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                          ))
                        }
                      </Select>
                    </Grid>

                    <br />

                    <Grid container alignItems='flex-start' spacing={2}>
                      {
                        values && createFormFields(values, isUpdate).map((item, idx) => (
                          <Grid item xs={item.size} key={idx} className={classes.flexGridItem}>
                            {item.field}
                            {
                              item.isCopy && item.name ?
                                <CopyToClipboard
                                  text={values[item.name]}
                                  onCopy={() => enqueueSnackbar('Copied!', {variant: 'success'})}
                                >
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    aria-label='copy'
                                  >
                                    <FileCopyOutlinedIcon fontSize='small' />
                                  </IconButton>
                                </CopyToClipboard> : null
                            }
                          </Grid>
                        ))
                      }
                      <Grid item xs={3} className={classes.flexGridItem}>
                        <Field name='isGateway' component={RadioAdapterGateway} validate={required} />
                      </Grid>
                    </Grid>
                  </Paper>
                  <br />

                  {
                    state && (
                      <Fragment>
                        <Typography component='label' variant='h6'>
                          Budget
                        </Typography>

                        <Paper style={{padding: 16}}>
                          <Grid container alignItems='flex-start' spacing={2}>
                            <Grid item xs={3} className={classes.flexGridItem}>
                              <TextField
                                id='dailyBudget'
                                name='dailyBudget'
                                label='Daily budget'
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3} className={classes.flexGridItem}>
                              <TextField
                                id='hourlyBudget'
                                name='hourlyBudget'
                                label='Hourly budget'
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems='flex-start' spacing={2}>
                            {
                              stringToBoolean(values.isGateway) && (
                                <Grid item xs={3} className={classes.flexGridItem}>
                                  <TextField
                                    id='minTopUpAmount'
                                    name='minTopUpAmount'
                                    label='Min top up amount ($)'
                                    fullWidth
                                    fieldProps={{validate: isNumber}}
                                  />
                                </Grid>
                              )
                            }
                            {
                              stringToBoolean(values.isGateway) && (
                                <Grid item xs={3} className={classes.flexGridItem}>
                                  <TextField
                                    id='discrepancy'
                                    name='discrepancy'
                                    label='Discrepancy (%)'
                                    fieldProps={{validate: composeValidators(isNumber, minDiscrepancyValue, maxDiscrepancyValue)}}
                                    fullWidth
                                  />
                                </Grid>
                              )
                            }
                          </Grid>
                        </Paper>
                        <br />
                      </Fragment>
                      )
                  }

                  <Typography component='label' variant='h6'>
                    Integration
                  </Typography>

                  <Paper style={{padding: 16}}>
                    <Grid container alignItems='flex-start' spacing={2}>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        <Field name='integrationType' component={RadioAdapterIntegrationType} validate={required} />
                      </Grid>
                      {
                        state && state.integrationType === 'RTB' ?
                          <Fragment>
                            <Grid item xs={6} className={classes.flexGridItem}>
                              <Select
                                name='location'
                                label='Data center location'
                                formControlProps={{margin: 'none'}}
                              >
                                <MenuItem value='Usa'>Usa</MenuItem>
                                <MenuItem value='Europe'>Europe</MenuItem>
                              </Select>
                            </Grid>

                            <Grid item xs={6} className={classes.flexGridItem}>
                              <Field name='protocolType' component={RadioAdapterProtocol} validate={required} />
                            </Grid>

                            <Grid item xs={6} className={classes.flexGridItem}>
                              <TextField
                                id='bidderUrl'
                                name='bidderUrl'
                                label='Bidder endpoint'
                                fullWidth
                                disabled
                                placeholder='http://endpoints.ubidex.bid'
                              />
                              <CopyToClipboard
                                text={values.bidderUrl}
                                onCopy={() => enqueueSnackbar('Copied!', {variant: 'success'})}
                              >
                                <IconButton
                                  size='small'
                                  color='primary'
                                  aria-label='copy'
                                >
                                  <FileCopyOutlinedIcon fontSize='small' />
                                </IconButton>
                              </CopyToClipboard>
                            </Grid>
                            {
                              state && state.protocolType === 'oRTB' ?
                                <Grid item xs={6} className={classes.flexGridItem}>
                                  <Field name='adType' component={RadioAdapterRtbAdType} validate={required} />
                                </Grid> :
                                <Grid item xs={6} className={classes.flexGridItem}>
                                  <Field name='adType' component={RadioAdapterAdType} validate={required} />
                                </Grid>
                            }

                            {
                              values.isGateway && (
                                <Grid item xs={6} className={classes.flexGridItem}>
                                  <TextField
                                    name='gatewayApiUrl'
                                    label='Statistics url'
                                    fullWidth
                                    disabled
                                    placeholder='http://stats.ugateway.xyz'
                                  />
                                  <CopyToClipboard
                                    text={values.gatewayApiUrl}
                                    onCopy={() => enqueueSnackbar('Copied!', {variant: 'success'})}
                                  >
                                    <IconButton
                                      size='small'
                                      color='primary'
                                      aria-label='copy'
                                    >
                                      <FileCopyOutlinedIcon fontSize='small' />
                                    </IconButton>
                                  </CopyToClipboard>
                                </Grid>
                              )
                            }

                            {
                              !isUpdate && (
                                <Grid item xs={6} className={classes.flexGridItem}>
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={() => resetForm(form)}
                                  >
                                    Reset
                                  </Button>
                                </Grid>
                              )
                            }

                            <Grid item xs={6} className={classes.flexGridItem}>
                              <Select
                                name='deviceType'
                                label='Device Type'
                                formControlProps={{margin: 'none'}}
                                multiple
                              >
                                <MenuItem value='Mobile'>Mobile</MenuItem>
                                <MenuItem value='Tablet'>Tablet</MenuItem>
                                <MenuItem value='Mobile / Tablet'>Mobile / Tablet</MenuItem>
                                <MenuItem value='Desktop'>Desktop</MenuItem>
                                <MenuItem value='Connected TV'>Connected TV</MenuItem>
                              </Select>
                            </Grid>

                            <Grid item xs={6} className={classes.flexGridItem}>
                              <TextField
                                id='impressionPostback'
                                name='impressionPostback'
                                label='Impression Postback'
                                fieldProps={{validate: isUrl}}
                              />
                              <CopyToClipboard
                                text={values.impresssionPostback}
                                onCopy={() => enqueueSnackbar('Copied!', {variant: 'success'})}
                              >
                                <IconButton
                                  size='small'
                                  color='primary'
                                  aria-label='copy'
                                >
                                  <FileCopyOutlinedIcon fontSize='small' />
                                </IconButton>
                              </CopyToClipboard>
                            </Grid>

                            {
                              state && state.protocolType === 'oRTB' ?
                                <Grid item xs={12} className={classes.flexGridItem}>
                                  <Field name='trafficType' component={RadioAdapterTrafficType} validate={required} />
                                </Grid> : <Grid item xs={12} className={classes.flexGridItem} />
                            }

                            <Grid item xs={3} className={classes.flexGridItem}>
                              <TextField
                                id='qpsThreshold'
                                name='qpsThreshold'
                                label='Max QPS'
                                notRemoveWhenEmpty
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} className={classes.flexGridItem}>
                              <Field name='isAdvanced' component={RadioAdapterAdvancedOptions} validate={required} />
                            </Grid>
                            {
                              state && stringToBoolean(String(state && state.isAdvanced)) ?
                                <Fragment>
                                  {
                                    state && state.integrationType === 'RTB' && state && state.protocolType === 'oRTB' ?
                                      <Fragment>
                                        <Grid item xs={12} className={classes.flexGridItem}>
                                          <Field name='auctionType' component={RadioAdapterAuctionType} validate={required} />
                                        </Grid>
                                        <Grid item xs={3} className={classes.flexGridItem}>
                                          <TextField
                                            id='tmax'
                                            name='tmax'
                                            label='Min timeout (tmax), ms'
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={9} className={classes.flexGridItem} />
                                        <Grid item xs={3} className={classes.flexGridItem}>
                                          <TextField
                                            id='defaultBidfloor'
                                            name='defaultBidfloor'
                                            label='Default bidfloor'
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={9} className={classes.flexGridItem} />
                                        <Grid item xs={12} className={classes.flexGridItem}>
                                          <Field name='paymentsWith' component={RadioAdapterPaymentsWith} validate={required} />
                                        </Grid>
                                        {
                                          state.protocolType === 'oRTB' && ['POP', 'PUSH', 'NATIVE'].includes(state.adType) ? null :
                                            state.adType === 'VIDEO' ?
                                              <Grid item xs={12} className={classes.flexGridItem}>
                                                <Field name='admFormat' component={RadioAdapterAdmFormat} validate={required} />
                                              </Grid> :
                                              <Grid item xs={12} className={classes.flexGridItem}>
                                                <Field name='isHtmlFormat' component={RadioAdapterHtmlFormat} validate={required} />
                                              </Grid>
                                        }
                                      </Fragment> :
                                      <Grid item xs={3} className={classes.flexGridItem}>
                                        <TextField
                                          id='tmax'
                                          name='tmax'
                                          label='Min timeout (tmax), ms'
                                          fullWidth
                                        />
                                      </Grid>
                                        }
                                </Fragment> : null
                            }
                            <Grid item xs={6} className={classes.flexGridItem} />
                            <Grid item xs={6} className={classes.flexGridItem} />
                          </Fragment> : null
                      }
                    </Grid>
                  </Paper>
                  <br />

                  {
                    state ?
                      <Fragment>
                        <Typography component='label' variant='h6'>
                          Validation
                        </Typography>
                        <Paper style={{padding: 16}}>
                          <Grid container alignItems='flex-start' spacing={2}>
                            <Grid item xs={12} className={classes.flexGridItem}>
                              <Field name='isValidationDefault' component={RadioAdapterValidationDefault} validate={required} />
                            </Grid>

                            {
                              state && !stringToBoolean(state && state.isValidationDefault) ?
                                <Fragment>
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <TextField
                                      id='impressionTtl'
                                      name='impressionTtl'
                                      label='Impression TTL (minutes)'
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <TextField
                                      id='maxErrorRate'
                                      name='maxErrorRate'
                                      label='Max Error Rate'
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <TextField
                                      id='maxNoMatchRate'
                                      name='maxNoMatchRate'
                                      label='Max no match rate'
                                      placeholder='(0-100%)'
                                      fullWidth
                                    />
                                  </Grid>
                                  {
                                    state && state.adType !== 'POP' ?
                                      <Grid item xs={6} className={classes.flexGridItem}>
                                        <TextField
                                          id='clickTtl'
                                          name='clickTtl'
                                          label='Click TTL (hours)'
                                          fullWidth
                                        />
                                      </Grid> : <Grid item xs={6} className={classes.flexGridItem} />
                                  }
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <Field name='isAllowImpDuplicates' component={RadioAdapterImpDuplicates} validate={required} />
                                  </Grid>
                                  {
                                    state && state.adType !== 'POP' ?
                                      <Grid item xs={6} className={classes.flexGridItem}>
                                        <Field name='isAllowClickDuplicates' component={RadioAdapterClickDuplicates} validate={required} />
                                      </Grid> : <Grid item xs={6} className={classes.flexGridItem} />
                                  }
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <Field name='isFilterMaskedIP' component={RadioAdapterFilterMaskedIP} validate={required} />
                                  </Grid>
                                  {
                                    state && state.adType !== 'POP' ?
                                      <Grid item xs={6} className={classes.flexGridItem}>
                                        <Field name='isClickWithoutImpRule2' component={RadioAdapterClickWithoutImpRule} validate={required} />
                                      </Grid> : <Grid item xs={6} className={classes.flexGridItem} />
                                  }
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <Field name='isLookupUserId' component={RadioAdapterLookupUserId} validate={required} />
                                  </Grid>
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <Field name='isSkipCreativeSize' component={RadioAdapterSkipCreativeSize} validate={required} />
                                  </Grid>
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <Field name='isCapPubUID' component={RadioAdapterCapPubUID} validate={required} />
                                  </Grid>
                                  <Grid item xs={6} className={classes.flexGridItem} />

                                  {
                                    state && state.protocolType === 'oRTB' ?
                                      <Grid item xs={6} className={classes.flexGridItem}>
                                        <Checkboxes
                                          label='Required bid requests parameters'
                                          name='bidParams'
                                          data={[
                                            {label: 'SubID', value: 'isSubID'},
                                            {label: 'TagID', value: 'isTagID'},
                                            {label: 'IFA', value: 'isIFA'},
                                            {label: 'GDPR', value: 'isGDPR'},
                                            {label: 'COPPA', value: 'isCOPPA'},
                                            {label: 'Make', value: 'isMake'},
                                            {label: 'City', value: 'isCity'},
                                            {label: 'LAN/LON', value: 'isLatLon'},
                                          ]}
                                        />
                                      </Grid> :
                                      <Grid item xs={6} className={classes.flexGridItem}>
                                        <Checkboxes
                                          label='Required bid requests parameters'
                                          name='bidParams'
                                          data={[
                                            {label: 'SubID', value: 'isSubID'},
                                            {label: 'Keyword', value: 'isKeyword'},
                                            {label: 'SubscriberID (sid)', value: 'isSubscriberID'},
                                            {label: 'SubscriberDate (sdate)', value: 'isSubscriberDate'},
                                          ]}
                                        />
                                      </Grid>
                                  }

                                  <Grid item xs={6} className={classes.flexGridItem} />
                                  <Grid item xs={6} className={classes.flexGridItem}>
                                    <Field name='isAllowMismatch' component={RadioAdapterMismatchParams} validate={required} />
                                  </Grid>
                                  {
                                    state && state.adType !== 'POP' &&
                                      <Grid item xs={6} className={classes.flexGridItem}>
                                        <Field name='isAllowClickMismatch' component={RadioAdapterAllowClickMismatch} validate={required} />
                                      </Grid>
                                  }
                                </Fragment> : null
                            }
                          </Grid>
                        </Paper>

                      </Fragment> :
                      null
                  }

                  <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item style={{marginTop: 16}}>
                      <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <ButtonSubmit
                          name='save'
                          onSubmit={handleSubmit}
                          isPending={submitting}
                          type='submit'
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          /> : null
      }
    </Fragment>
  );
}
