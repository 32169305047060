import {Link} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

export const getColumns = (props) => [
  {
    field: 'id',
    headerName: 'Name',
    sortable: true,
    disableClickEventBubbling: true,
    renderCell: ({row}) => {
      return <Link style={{cursor: 'pointer', textDecoration: 'none'}}>({row.id}) {row.name}</Link>;
    },
    sortComparator: (v1, v2) => v1 - v2,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    disableClickEventBubbling: true,
    renderCell: (params) => {
      const classStatus = clsx('status', {
        new: params.value.toLowerCase() === 'new',
        active: params.value.toLowerCase() === 'active',
        paused: params.value.toLowerCase() === 'paused',
        pending: params.value.toLowerCase() === 'pending',
        removed: params.value.toLowerCase() === 'removed',
      });

      return (
        <span className={classStatus}>{params.value}</span>
      );
    },
    sortable: true,
    flex: 1,
  },
  {
    field: 'balance',
    headerName: 'Balance',
    sortable: true,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Registered',
    valueFormatter: (params) => {
      const valueFormatted = params.value ? params.value.slice(0, 10) : params.value;
      return `${valueFormatted}`;
    },
    sortable: true,
    flex: 1,
  },
];
