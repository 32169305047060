import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

// Admin Panel app reducers
import auth from '../services/auth/reducers';
import platform from '../services/platform/reducers';
import {LOG_OUT} from '../services/auth/types';

const appReducers = combineReducers({
  form: formReducer,
  auth,
  platform,
});

export default (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined;
  }

  return appReducers(state, action);
};
