import React from 'react';
import {DataGrid, gridDataRowIdsSelector, GridToolbarContainer, useGridApiContext} from '@mui/x-data-grid';
import {createTheme, makeStyles, Button, Box} from '@material-ui/core';
//
import {ExportIcon} from '../common/icons';
import {DAILY_COLUMN_DEFS, DAILY_COLUMN_GROUPING} from 'utils/tables/columns/reports/daily';

const defaultTheme = createTheme();

const useStyles = makeStyles(
    (theme) => ({
        root: {
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                outline: 'none',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
        },
        colCell: {
            flex: 1,
            minWidth: '1px !important',
            maxWidth: 'none !important',
        },
        viewport: {
            '& .rendering-zone': {
                width: 'initial !important',
                maxWidth: 'initial !important',
            },
        },
        row: {
            width: '100% !important',
        },
        colCellWrapper: {
            display: 'flex',
        },
        cell: {
            'flex': 1,
            'minWidth': '1px !important',
            'maxWidth': 'none !important',
            '&:last-of-type': {
                minWidth: '0 !important',
                flex: 0,
            },
        },
        toolbarContainer: {
            'display': 'flex',
            'justify-content': 'space-between',
        },
        textField: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            'margin': '5px 0 0 15px',
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(0.5),
            },
            '& .MuiInput-underline:before': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
        button: {
            'margin': '5px 5px 0 0',
        },
    }),
    {defaultTheme},
);

const getRowsFromCurrentPage = ({apiRef}) =>
    gridDataRowIdsSelector(apiRef);

const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    const handleExport = (options) =>
        apiRef.current.exportDataAsCsv(options);

    const buttonBaseProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon />,
    };

    return (
      <GridToolbarContainer>
        <Box sx={{marginTop: '15px'}}>
          <Button
            {...buttonBaseProps}
            onClick={() => handleExport({getRowsToExport: getRowsFromCurrentPage})}
          >
            Download as .CSV
          </Button>
        </Box>
      </GridToolbarContainer>
    );
};

const ReportTable = (props) => {
   const classes = useStyles();

   const reportColumnsDef = DAILY_COLUMN_DEFS.filter((column) => {
     if (props.groupBy === 'createdDate' && (column.field === 'whLabelUser' || column.field === 'uPublisher' || column.field === 'geo')) {
       return false;
     }
     if (props.groupBy === 'wlid' && (column.field === 'createdDate' || column.field === 'uPublisher' || column.field === 'geo')) {
       return false;
     }
     if (props.groupBy === 'uPublisherId' && (column.field === 'createdDate' || column.field === 'whLabelUser' || column.field === 'geo')) {
       return false;
     }
     if (props.groupBy === 'geo' && (column.field === 'createdDate' || column.field === 'whLabelUser' || column.field === 'uPublisher')) {
       return false;
     }
     return true;
   });

   return (
     <div style={{width: '100%', marginTop: '15px'}}>
       <DataGrid
         rows={props.rowData || []}
         rowCount={props.count}
         paginationMode="server"
         components={{Toolbar: CustomToolbar}}
         loading={props.isRequestPending}
         columns={reportColumnsDef}
         pageSizeOptions={[100]}
         paginationModel={props.paginationModel}
         onPaginationModelChange={props.onPaginationChange}
         className={`${classes.root}`}
         experimentalFeatures={{columnGrouping: true}}
         columnGroupingModel={DAILY_COLUMN_GROUPING}
       />
     </div>
   );
};

export default ReportTable;
