import axios from 'axios';
import {showSuccessNotification} from 'utils/common';

export const changeBiddingStatus = async (clientIds, isStopBidding, dataCenter) => {
  try {
    const {data} = await axios.put(`/v1/whitelabel/bidding`, {clientIds, isStopBidding, dataCenter});
    if (data === 'OK') {
      window.location.href = '/dashboard';
    }
  } catch (err) {
    console.error(`Error changeBiddingStatus`);
  }
};

export const changeAllBiddingStatus = async (isStopBidding) => {
  try {
    const {data} = await axios.put(`/v1/whitelabel/all-bidding`, {isStopBidding});
    if (data === 'OK') {
      showSuccessNotification('Success!');
    }
  } catch (err) {
    console.error(`Error changeBiddingStatus`);
  }
};
