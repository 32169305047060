'use strict';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// Routes
// import AuthRoute from '../../routes/AuthRouter';
// import PrivateRouter from '../../routes/PrivateRouter';
import IndexRouter from '../../routes';
import {signInViaToken} from '../../actions';
import {loadPlatformSettings} from '../../services/platform/actions';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    this.init = this.init.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.init();
  }

  componentDidMount() {
    this.props.actions.loadPlatformSettings();
  }

  async init() {
    const db1 = await signInViaToken();
    this.setState({
      user: db1 ? db1.user : null,
    });
  }

  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
        <IndexRouter
          {...this.state}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    loadPlatformSettings,
  }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(App));
