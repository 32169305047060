import React, {Fragment, memo, useState, useEffect} from 'react';
import {Form} from 'react-final-form';
import {useDispatch} from 'react-redux';
import Box from '@mui/material/Box';
import {Grid, makeStyles, Paper} from '@material-ui/core';
import {TextField} from 'mui-rff';
import {DataGrid} from '@mui/x-data-grid';
import {useSnackbar} from 'notistack';
//
import Title from 'components/common/Title';
import Loader from 'components/common/Loader';
import ButtonSubmit from 'components/common/ButtonSubmit';
import {composeValidators, required, isNumber, isNumberAndNegative, maxValue} from 'utils/validate';
import {TRANSACTIONS_HISTORY_COLUMN_DEFS} from 'utils/tables/columns/transactionsHistory';
import {
    getGatewayBudgetByID,
    getGatewayTransactionList,
    updateWLGatewayBudget,
} from 'actions/index';
import {syncPlatformUnassignedBalance} from '../../../../services/platform/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .payment.manual': {
      backgroundColor: '#1578b6',
    },
    '& .payment.crypto': {
      backgroundColor: '#3ed436',
    },
    '& .payment.paypal': {
      backgroundColor: '#f6ab2e',
    },
    '& .status.new': {
      backgroundColor: '#1578b6',
    },
    '& .status.succeeded': {
      backgroundColor: '#3ed436',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  },
  colCell: {
    flex: 1,
    minWidth: '1px !important',
    maxWidth: 'none !important',
  },
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '30px',
  },
}));

const maxCommissionValue = maxValue(100);

const Gateway = (props) => {
    // hooks
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [budgetData, setBudgetData] = useState({balance: 0, remainingBudget: 0});
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const id = props.match.params.id;

          const data = await getGatewayBudgetByID(id);
          setBudgetData({balance: data.balance, remainingBudget: data.remainingBudget});

          if (data.wlid) {
            const transactionList = await getGatewayTransactionList(data.wlid);
            setTransactions(transactionList);
          }
        };

        fetchData();
    }, []);

    // actions
    const onSubmit = (values, form) => {
      const {commission, deposit} = values;

      const amount = deposit * ((100 - commission) / 100);

      const balance = budgetData.balance + amount;
      const remainingBudget = budgetData.remainingBudget + amount;

      setBudgetData({balance, remainingBudget});

      return new Promise(async (resolve) => {

        const data = {deposit, commission};

        const budgetTransaction = await updateWLGatewayBudget(props.match.params.id, data);

        // Update redux store
        dispatch(syncPlatformUnassignedBalance(amount));

        setTransactions([budgetTransaction, ...transactions]);

        enqueueSnackbar('Success!', {variant: 'success'});

        resolve();
      });
    };

    return (
      <Fragment>
        <Paper>
          <Form
            initialValues={budgetData}
            onSubmit={(values) => onSubmit(values)}
            render={({handleSubmit, reset, form, submitting}) => (
              <form
                onSubmit={(e) => {
                  form.resetFieldState('commission');
                  form.resetFieldState('deposit');
                  handleSubmit(e);
                }}
                noValidate
              >
                <Loader isPending={submitting} />
                <Paper style={{padding: 16}}>
                  <Grid item xs={6} className={classes.flexGridItem}>
                    <TextField
                      name='balance'
                      label='Total Balance'
                      value={budgetData.balance}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name='remainingBudget'
                      label='Remaining Balance'
                      value={budgetData.remainingBudget}
                      style={{marginLeft: '20px'}}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={7} className={classes.flexGridItem}>
                    <TextField
                      name='commission'
                      label='Commission (%)'
                      fieldProps={{validate: composeValidators(required, isNumber, maxCommissionValue)}}
                      fullWidth
                    />

                    <TextField
                      name='deposit'
                      label='Top Up Balance'
                      fieldProps={{validate: composeValidators(required, isNumberAndNegative)}}
                      style={{marginLeft: '20px'}}
                      fullWidth
                    />

                    <ButtonSubmit
                      name='add'
                      // onSubmit={handleSubmit}
                      type='submit'
                      style={{marginLeft: '20px'}}
                    />
                  </Grid>
                </Paper>
              </form>
                )}
          />
        </Paper>
        <Paper>
          <div style={{width: '100%', marginTop: '15px'}}>
            <Box sx={{pt: 2, px: 2}}>
              <Title>Transaction History</Title>
            </Box>
            <Box sx={{pt: 2, px: 2}}>
              <DataGrid
                rows={transactions}
                columns={TRANSACTIONS_HISTORY_COLUMN_DEFS}
                className={`${classes.root}`}
              />
            </Box>
          </div>
        </Paper>
      </Fragment>
    );
};

export default React.memo(Gateway, (prevProps, nextProps) => prevProps === nextProps);
