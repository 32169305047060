import * as React from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {CssBaseline} from '@material-ui/core';
import General from './tabs/General';
import AppDomain from './tabs/AppDomain';
import TraffDomain from './tabs/TraffDomain';
import TrackDomain from './tabs/TrackDomain';
import AdDomain from './tabs/AdDomain';
import Subscription from './tabs/Subscription';
import Gateway from './tabs/Gateway';
import ReportDomain from './tabs/ReportDomain';

export default function EditPage(props) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{paddingTop: 64, margin: 'auto', maxWidth: '1200px'}}>
      <CssBaseline />
      <br />
      <Box sx={{width: '100%', typography: 'body1'}}>
        <TabContext value={value}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleChange} aria-label='tabs' centered>
              <Tab label='General' value='1' />
              <Tab label='Subscription' value='2' disabled={!props.isUpdate} />
              <Tab label='Gateway' value='3' disabled={!props.isUpdate} />
              <Tab label='UI' value='4' disabled={!props.isUpdate} />
              <Tab label='Ad' value='5' disabled={!props.isUpdate} />
              <Tab label='Traff' value='6' disabled={!props.isUpdate} />
              <Tab label='Track' value='7' disabled={!props.isUpdate} />
              <Tab label='Report' value='8' disabled={!props.isUpdate} />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <General isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='2'>
            <Subscription isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='3'>
            <Gateway isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='4'>
            <AppDomain isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='5'>
            <AdDomain isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='6'>
            <TraffDomain isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='7'>
            <TrackDomain isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
          <TabPanel value='8'>
            <ReportDomain isUpdate={props.isUpdate} match={props.match} history={props.history} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
