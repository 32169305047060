import {createStore, applyMiddleware, compose} from 'redux';
// import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState, history) => {
  // const logger = createLogger();

  // FIXME Add logger for dev mode only
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
