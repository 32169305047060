import React from 'react';
import ReactCountryFlag from 'react-country-flag';
//
import {alpha2CodeMap} from '../../../../constants/alpha2';

export const DAILY_COLUMN_DEFS = [
  {field: 'createdDate', headerName: 'Date'},
  {
    field: 'whLabelUser',
    headerName: 'Client',
    width: 190,
    sortComparator: (v1, v2) => {
      const prevValue = Number.isInteger(v1) ? v1 : v1.match(/\((.*)\)/).pop();
      const nextValue = Number.isInteger(v2) ? v2 : v2.match(/\((.*)\)/).pop();

      return prevValue - nextValue;
    },
  },
  {
    field: 'uPublisher',
    headerName: 'Endpoint',
    width: 190,
    sortComparator: (v1, v2) => {
      const prevValue = Number.isInteger(v1) ? v1 : v1.match(/\((.*)\)/).pop();
      const nextValue = Number.isInteger(v2) ? v2 : v2.match(/\((.*)\)/).pop();

      return prevValue - nextValue;
    },
  },
  {
      field: 'geo',
      headerName: 'Geo',
      width: 120,
      renderCell: (params) => {
        if (!params.formattedValue) return null;

        const country = alpha2CodeMap.hasOwnProperty(params.formattedValue) ? alpha2CodeMap[params.formattedValue] : {};

        return (
          <span>
            <ReactCountryFlag
              className='emojiFlag'
              countryCode={params.formattedValue}
              style={{
                fontSize: '2em',
                lineHeight: '2em',
              }}
              title={country.label}
            />
            {` ` + params.formattedValue}
          </span>

        );
      },
  },
  // {field: 'requests', headerName: 'Requests', width: 130},
  {field: 'responses', headerName: 'Responses', width: 130},
  {field: 'bidRate', headerName: 'Bid Rate (%)', width: 130},
  {field: 'revenue', headerName: 'Revenue ($)', width: 130},
  {field: 'payout', headerName: 'Payout ($)'},
  {field: 'profit', headerName: 'Profit ($)'},

  {field: 'impressions', headerName: 'Total'},
  {field: 'approvedImpressions', headerName: 'Approved'},
  {field: 'percentApprovedImpressions', headerName: '%'},
  {field: 'fillRate', headerName: 'Fill Rate (%)', width: 130},
  {field: 'winRate', headerName: 'Win Rate (%)', width: 130},

  // {field: 'percentRejectedImpressions', headerName: 'Total (%)', width: 130},
  // {field: 'percentDuplicateImpressions', headerName: 'Duplicate (%)', width: 130},
  // {field: 'percentExpiredImpressions', headerName: 'TTL (%)'},
  // {field: 'percentIpImpressions', headerName: 'IP (%)'},
  // {field: 'percentGeoImpressions', headerName: 'Geo (%)'},
  // {field: 'percentDeviceImpressions', headerName: 'Device (%)', width: 130},
  // {field: 'percentOsImpressions', headerName: 'Os (%)'},
  // {field: 'percentOtherImpressions', headerName: 'Other (%)'},
  //
  // {field: 'impressionsImage', headerName: 'Total'},
  // {field: 'approvedImpressionsImage', headerName: 'Approved'},
  // {field: 'percentApprovedImage', headerName: '(%)'},
  // {field: 'fillRateImage', headerName: 'Fill Rate (%)', width: 130},
  // {field: 'winRateImage', headerName: 'Win Rate (%)', width: 130},

  // {field: 'percentRejectedImpressionsImage', headerName: 'Total (%)', width: 130},
  // {field: 'percentDuplicateImpressionsImage', headerName: 'Duplicate (%)', width: 130},
  // {field: 'percentExpiredImpressionsImage', headerName: 'TTL (%)'},
  // {field: 'percentIpImpressionsImage', headerName: 'IP (%)'},
  // {field: 'percentGeoImpressionsImage', headerName: 'Geo (%)'},
  // {field: 'percentDeviceImpressionsImage', headerName: 'Device (%)', width: 130},
  // {field: 'percentOsImpressionsImage', headerName: 'Os (%)'},
  // {field: 'percentOtherImpressionsImage', headerName: 'Other (%)'},

  // {field: 'impressionsIcon', headerName: 'Total'},
  // {field: 'approvedImpressionsIcon', headerName: 'Approved'},
  // {field: 'percentApprovedIcon', headerName: '(%)'},
  // {field: 'fillRateIcon', headerName: 'Fill Rate (%)', width: 130},
  // {field: 'winRateIcon', headerName: 'Win Rate (%)', width: 130},

  // {field: 'percentRejectedImpressionsIcon', headerName: 'Total (%)', width: 130},
  // {field: 'percentDuplicateImpressionsIcon', headerName: 'Duplicate (%)', width: 130},
  // {field: 'percentExpiredImpressionsIcon', headerName: 'TTL (%)'},
  // {field: 'percentIpImpressionsIcon', headerName: 'IP (%)'},
  // {field: 'percentGeoImpressionsIcon', headerName: 'Geo (%)'},
  // {field: 'percentDeviceImpressionsIcon', headerName: 'Device (%)', width: 130},
  // {field: 'percentOsImpressionsIcon', headerName: 'Os (%)'},
  // {field: 'percentOtherImpressionsIcon', headerName: 'Other (%)', width: 130},
  //
  // {field: 'wins', headerName: 'Total'},
  // {field: 'approvedWins', headerName: 'Approved'},
  // {field: 'percentApprovedWins', headerName: '(%)'},
  // {field: 'fillRateWin', headerName: 'Fill Rate (%)', width: 130},
  // {field: 'winRateWin', headerName: 'Win Rate (%)', width: 130},

  // {field: 'percentRejectedWins', headerName: 'Total (%)', width: 130},
  // {field: 'percentDuplicateWins', headerName: 'Duplicate (%)', width: 130},
  // {field: 'percentExpiredWins', headerName: 'TTL (%)'},
  // {field: 'percentOtherWins', headerName: 'Other (%)'},

  {field: 'clicks', headerName: 'Total'},
  {field: 'approvedClicks', headerName: 'Approved'},
  {field: 'percentApprovedClicks', headerName: '(%)'},
  {field: 'ctr', headerName: 'CTR (%)'},

  // {field: 'percentRejectedClicks', headerName: 'Total (%)', width: 130},
  // {field: 'percentDuplicateClicks', headerName: 'Duplicate (%)', width: 130},
  // {field: 'percentExpiredClicks', headerName: 'TTL (%)'},
  // {field: 'percentIpClicks', headerName: 'IP (%)'},
  // {field: 'percentGeoClicks', headerName: 'Geo (%)'},
  // {field: 'percentDeviceClicks', headerName: 'Device (%)', width: 130},
  // {field: 'percentOsClicks', headerName: 'Os (%)'},
  // {field: 'percentNoimpClicks', headerName: 'No Imp (%)', width: 130},
  // {field: 'percentOtherClicks', headerName: 'Other (%)'},
    //
    // {field: 'visitPixelsCount', headerName: 'Visit'},
    // {field: 'cpaVisitPixels', headerName: 'CPA ($)'},
    // {field: 'lpViewPixelsCount', headerName: 'LP View'},
    // {field: 'cpaLpViewPixels', headerName: 'CPA ($)'},
    //
    // {field: 'lpClickPixelsCount', headerName: 'LP Click'},
    // {field: 'cpaLpClickPixels', headerName: 'CPA ($)'},
    //
    // {field: 'regStartedPixelsCount', headerName: 'RegStart'},
    // {field: 'cpaRegStartedPixels', headerName: 'CPA ($)'},
    //
    // {field: 'regFinishedPixelsCount', headerName: 'RegFin'},
    // {field: 'cpaRegFinishedPixels', headerName: 'CPA ($)'},
    //
    // {field: 'loginPixelsCount', headerName: 'Login'},
    // {field: 'cpaLoginPixels', headerName: 'CPA ($)'},
    //
    // {field: 'ftdPixelsCount', headerName: 'FTD'},
    // {field: 'cpaFtdPixels', headerName: 'CPA ($)'},
    //
    // {field: 'depositPixelsCount', headerName: 'Deposit'},
    // {field: 'cpaDepositPixels', headerName: 'CPA ($)'},
    //
    // {field: 'otherPixelsCount', headerName: 'Other'},
    // {field: 'cpaOtherPixelsCount', headerName: 'CPA ($)'},

    {field: 'eCPM', headerName: 'eCPM ($)', width: 100},
    {field: 'eCPC', headerName: 'eCPC ($)', width: 100},
    {field: 'bidFloor', headerName: 'Bid floor ($)', width: 130},
    {field: 'bidPrice', headerName: 'Bid price ($)', width: 130},

];

export const DAILY_COLUMN_GROUPING = [
    {
        groupId: 'base',
        headerName: '',
        children: [
            {field: 'createdDate'},
            {field: 'whLabelUser'},
            {field: 'uPublisher'},
            {field: 'geo'},
            // {field: 'requests'},
            {field: 'responses'},
            {field: 'bidRate'},
        ],
    },
    {
        groupId: 'impressions',
        headerName: 'Impressions',
        children: [
            {field: 'impressions'},
            {field: 'approvedImpressions'},
            {field: 'percentApprovedImpressions'},
            {field: 'fillRate'},
            {field: 'winRate'},
        ],
    },
    {
        groupId: 'invalidImpressions',
        headerName: 'Invalid Impressions',
        children: [
            {field: 'percentRejectedImpressions'},
            {field: 'percentDuplicateImpressions'},
            {field: 'percentExpiredImpressions'},
            {field: 'percentIpImpressions'},
            {field: 'percentGeoImpressions'},
            {field: 'percentDeviceImpressions'},
            {field: 'percentOsImpressions'},
            {field: 'percentOtherImpressions'},
        ],
    },
    {
        groupId: 'impressionsImage',
        headerName: 'Impressions Image',
        children: [
            {field: 'impressionsImage'},
            {field: 'approvedImpressionsImage'},
            {field: 'percentApprovedImage'},
            {field: 'fillRateImage'},
            {field: 'winRateImage'},
        ],
    },
    {
        groupId: 'invalidImpressionsImage',
        headerName: 'Invalid Impressions Image',
        children: [
            {field: 'percentRejectedImpressionsImage'},
            {field: 'percentDuplicateImpressionsImage'},
            {field: 'percentExpiredImpressionsImage'},
            {field: 'percentIpImpressionsImage'},
            {field: 'percentGeoImpressionsImage'},
            {field: 'percentDeviceImpressionsImage'},
            {field: 'percentOsImpressionsImage'},
            {field: 'percentOtherImpressionsImage'},
        ],
    },

    {
        groupId: 'impressionsIcon',
        headerName: 'Impressions Icon',
        children: [
            {field: 'impressionsIcon'},
            {field: 'approvedImpressionsIcon'},
            {field: 'percentApprovedIcon'},
            {field: 'fillRateIcon'},
            {field: 'winRateIcon'},
        ],
    },

    {
        groupId: 'invalidImpressionsIcon',
        headerName: 'Invalid Impressions Icon',
        children: [
            {field: 'percentRejectedImpressionsIcon'},
            {field: 'percentDuplicateImpressionsIcon'},
            {field: 'percentExpiredImpressionsIcon'},
            {field: 'percentIpImpressionsIcon'},
            {field: 'percentGeoImpressionsIcon'},
            {field: 'percentDeviceImpressionsIcon'},
            {field: 'percentOsImpressionsIcon'},
            {field: 'percentOtherImpressionsIcon'},
        ],
    },

    {
        groupId: 'wins',
        headerName: 'Wins',
        children: [
            {field: 'wins'},
            {field: 'approvedWins'},
            {field: 'percentApprovedWins'},
            {field: 'fillRateWin'},
            {field: 'winRateWin'},
        ],
    },

    {
        groupId: 'invalidWins',
        headerName: 'Invalid Wins',
        children: [
            {field: 'percentRejectedWins'},
            {field: 'percentDuplicateWins'},
            {field: 'percentExpiredWins'},
            {field: 'percentOtherWins'},
        ],
    },

    {
        groupId: 'clicks',
        headerName: 'Clicks',
        children: [
            {field: 'clicks'},
            {field: 'approvedClicks'},
            {field: 'percentApprovedClicks'},
            {field: 'ctr'},
        ],
    },

    {
        groupId: 'invalidClicks',
        headerName: 'Invalid Clicks',
        children: [
            {field: 'percentRejectedClicks'},
            {field: 'percentDuplicateClicks'},
            {field: 'percentExpiredClicks'},
            {field: 'percentIpClicks'},
            {field: 'percentGeoClicks'},
            {field: 'percentDeviceClicks'},
            {field: 'percentOsClicks'},
            {field: 'percentNoimpClicks'},
            {field: 'percentOtherClicks'},
        ],
    },

    {
        groupId: 'postbacks',
        headerName: 'Postbacks',
        children: [
            {field: 'postbacks'},
            {field: 'approvedPostbacks'},
            {field: 'percentApprovedPostbacks'},
            {field: 'cr'},
        ],
    },

    {
        groupId: 'invalidPostbacks',
        headerName: 'Invalid Postbacks',
        children: [
            {field: 'rejectedPostbacks'},
            {field: 'percentRejectedPostbacks'},
            {field: 'expiredPostbacks'},
            {field: 'percentExpiredPostbacks'},
        ],
    },

    {
        groupId: 'goalTypes',
        headerName: 'Goal Types',
        children: [
            {field: 'installPostbacksCount'},
            {field: 'cpaInstallPostabacks'},
            {field: 'earningsInstallPostabacks'},
            {field: 'roiInstallPostabacks'},
            {field: 'subscriptionPostbacksCount'},
            {field: 'cpaSubscriptionPostbacks'},
            {field: 'earningsSubscriptionPostbacks'},
            {field: 'roiSubscriptionPostbacks'},
            {field: 'purchasePostbacksCount'},
            {field: 'cpaPurchasePostbacks'},
            {field: 'earningsPurchasePostbacks'},
            {field: 'roiPurchasePostbacks'},
            {field: 'fTimeDepositPostbacksCount'},
            {field: 'cpaFTimeDepositPostbacks'},
            {field: 'earningsFTimeDepositPostbacks'},
            {field: 'roiFTimeDepositPostbacks'},
            {field: 'depositPostbacksCount'},
            {field: 'cpaDepositPostbacks'},
            {field: 'earningsDepositPostbacks'},
            {field: 'roiDepositPostbacks'},
            {field: 'regStartedPostbacksCount'},
            {field: 'cpaRegStartedPostbacks'},
            {field: 'earningsRegStartedPostbacks'},
            {field: 'roiRegStartedPostbacks'},
            {field: 'regFinishedPostbacksCount'},
            {field: 'cpaRegFinishedPostbacks'},
            {field: 'earningsRegFinishedPostbacks'},
            {field: 'roiRegFinishedPostbacks'},
            {field: 'downloadPostbacksCount'},
            {field: 'cpaDownloadPostbacks'},
            {field: 'earningsDownloadPostbacks'},
            {field: 'roiDownloadPostbacks'},
            {field: 'spentPostbacksCount'},
            {field: 'cpaSpentPostbacks'},
            {field: 'earningsSpentPostbacks'},
            {field: 'roiSpentPostbacks'},
            {field: 'timeSpentPostbacksCount'},
            {field: 'cpaTimeSpentPostbacks'},
            {field: 'earningsTimeSpentPostbacks'},
            {field: 'roiTimeSpentPostbacks'},
            {field: 'transactionPostbacksCount'},
            {field: 'cpaTransactionPostbacks'},
            {field: 'earningsTransactionPostbacks'},
            {field: 'roiTransactionPostbacks'},
            {field: 'rejectedInstallPostbacksCount'},
            {field: 'cpaRejectedInstallPostbacks'},
            {field: 'earningsRejectedInstallPostbacks'},
            {field: 'roiRejectedInstallPostbacks'},
            {field: 'step1PostbacksCount'},
            {field: 'cpaStep1Postbacks'},
            {field: 'earningsStep1Postbacks'},
            {field: 'roiStep1Postbacks'},
            {field: 'step2PostbacksCount'},
            {field: 'cpaStep2Postbacks'},
            {field: 'earningsStep2Postbacks'},
            {field: 'roiStep2Postbacks'},
            {field: 'step3PostbacksCount'},
            {field: 'cpaStep3Postbacks'},
            {field: 'earningsStep3Postbacks'},
            {field: 'roiStep3Postbacks'},
            {field: 'otherPostbacksCount'},
            {field: 'cpaOtherPostbacks'},
            {field: 'earningsOtherPostbacks'},
            {field: 'roiOtherPostbacks'},
        ],
    },

    {
        groupId: 'pixelTypes',
        headerName: 'Pixel Types',
        children: [
            {field: 'visitPixelsCount'},
            {field: 'cpaVisitPixels'},
            {field: 'lpViewPixelsCount'},
            {field: 'cpaLpViewPixels'},
            {field: 'lpClickPixelsCount'},
            {field: 'cpaLpClickPixels'},
            {field: 'regStartedPixelsCount'},
            {field: 'cpaRegStartedPixels'},
            {field: 'regFinishedPixelsCount'},
            {field: 'cpaRegFinishedPixels'},
            {field: 'loginPixelsCount'},
            {field: 'cpaLoginPixels'},
            {field: 'ftdPixelsCount'},
            {field: 'cpaFtdPixels'},
            {field: 'depositPixelsCount'},
            {field: 'cpaDepositPixels'},
            {field: 'otherPixelsCount'},
            {field: 'cpaOtherPixelsCount'},
        ],
    },

    {
        groupId: 'moneyStats',
        headerName: '',
        children: [
            {field: 'eCPM'},
            {field: 'eCPC'},
            {field: 'eCPA'},
            {field: 'bidFloor'},
            {field: 'bidPrice'},
            {field: 'revenue'},
            {field: 'payout'},
            {field: 'profit'},
        ],
    },
];
