import React, {Fragment, useState, useEffect, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {NotificationManager} from 'react-notifications';
import {randomString} from 'utils/common';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#2196f3',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const fileValidator = (file, props) => {
  const names = file.name.split('.');

  const fileExtension = names ? `.${names.pop()}` : null;

  if (props.extensions.length && !props.extensions.includes(fileExtension)) {
    NotificationManager.error(`Wrong file type. Upload ${props.extensions.toString()} file`);
    return {};
  }

  return null;
};

const FilesDropZone = (props) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (props.state && props.state.logoUrl) {
      const file = {preview: {url: props.state.logoUrl}};

      setFiles([file]);
    }
  }, [props.state]);

  const placeholderText = 'Support for a single upload';

  const renameFile = (originalFile) => {
    const splittedName = originalFile.name.split('.');
    const fileExtension = splittedName[splittedName.length - 1].replace(/[^a-zA-Z0-9]/g, '');
    const fileName = randomString(12);

    const modifiedFile = new File([originalFile], `${fileName}.${fileExtension}`, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });

    modifiedFile.path = modifiedFile.name;

    return modifiedFile;
  };

  const onDrop = (acceptedFiles) => {
    const uploadedFiles = [];
    acceptedFiles.forEach((file) => {
      const modifiedFile = renameFile(file);
      const uploadedFile = Object.assign(modifiedFile, {
        preview: {url: URL.createObjectURL(modifiedFile)},
      });
      uploadedFiles.push(uploadedFile);
    });

    if (uploadedFiles.length > 10) {
      NotificationManager.error(`Too many creatives selected. Please choose up to 10 files at a time`);
      return null;
    } else {
      setFiles(uploadedFiles);
      return props.onUploadFile(uploadedFiles);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: props.isMulti,
    validator: (file) => fileValidator(file, props),
  });

  const style = useMemo(() => ({
    ...baseStyle,
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  return (
    <Fragment>
      {props.title ? <span className='form__text-field__name'>{props.title}</span> : null}
      <div className='container'>
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          {
            files.length ?
              <img src={files[0].preview.url} width={200} /> :
              <React.Fragment>
                <p style={{color: '#505050'}}>Click or drag file to this area to upload</p>
                <p>{placeholderText}</p>
              </React.Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default FilesDropZone;
