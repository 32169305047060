export const alpha2CodeArray = [
  {'name': 'Afghanistan', 'alpha2Code': 'AF'},
  {'name': 'Aland Islands', 'alpha2Code': 'AX'},
  {'name': 'Albania', 'alpha2Code': 'AL'},
  {'name': 'Algeria', 'alpha2Code': 'DZ'},
  {'name': 'American Samoa', 'alpha2Code': 'AS'},
  {'name': 'Andorra', 'alpha2Code': 'AD'},
  {'name': 'Angola', 'alpha2Code': 'AO'},
  {'name': 'Anguilla', 'alpha2Code': 'AI'},
  {'name': 'Antarctica', 'alpha2Code': 'AQ'},
  {'name': 'Antigua And Barbuda', 'alpha2Code': 'AG'},
  {'name': 'Argentina', 'alpha2Code': 'AR'},
  {'name': 'Armenia', 'alpha2Code': 'AM'},
  {'name': 'Aruba', 'alpha2Code': 'AW'},
  {'name': 'Australia', 'alpha2Code': 'AU'},
  {'name': 'Austria', 'alpha2Code': 'AT'},
  {'name': 'Azerbaijan', 'alpha2Code': 'AZ'},
  {'name': 'Bahamas', 'alpha2Code': 'BS'},
  {'name': 'Bahrain', 'alpha2Code': 'BH'},
  {'name': 'Bangladesh', 'alpha2Code': 'BD'},
  {'name': 'Barbados', 'alpha2Code': 'BB'},
  {'name': 'Belarus', 'alpha2Code': 'BY'},
  {'name': 'Belgium', 'alpha2Code': 'BE'},
  {'name': 'Belize', 'alpha2Code': 'BZ'},
  {'name': 'Benin', 'alpha2Code': 'BJ'},
  {'name': 'Bermuda', 'alpha2Code': 'BM'},
  {'name': 'Bhutan', 'alpha2Code': 'BT'},
  {'name': 'Bolivia', 'alpha2Code': 'BO'},
  {'name': 'Bosnia And Herzegovina', 'alpha2Code': 'BA'},
  {'name': 'Botswana', 'alpha2Code': 'BW'},
  {'name': 'Bouvet Island', 'alpha2Code': 'BV'},
  {'name': 'Brazil', 'alpha2Code': 'BR'},
  {'name': 'British Indian Ocean Territory', 'alpha2Code': 'IO'},
  {'name': 'Brunei Darussalam', 'alpha2Code': 'BN'},
  {'name': 'Bulgaria', 'alpha2Code': 'BG'},
  {'name': 'Burkina Faso', 'alpha2Code': 'BF'},
  {'name': 'Burundi', 'alpha2Code': 'BI'},
  {'name': 'Cambodia', 'alpha2Code': 'KH'},
  {'name': 'Cameroon', 'alpha2Code': 'CM'},
  {'name': 'Canada', 'alpha2Code': 'CA'},
  {'name': 'Cape Verde', 'alpha2Code': 'CV'},
  {'name': 'Cayman Islands', 'alpha2Code': 'KY'},
  {'name': 'Central African Republic', 'alpha2Code': 'CF'},
  {'name': 'Chad', 'alpha2Code': 'TD'},
  {'name': 'Chile', 'alpha2Code': 'CL'},
  {'name': 'China', 'alpha2Code': 'CN'},
  {'name': 'Christmas Island', 'alpha2Code': 'CX'},
  {'name': 'Cocos (Keeling) Islands', 'alpha2Code': 'CC'},
  {'name': 'Colombia', 'alpha2Code': 'CO'},
  {'name': 'Comoros', 'alpha2Code': 'KM'},
  {'name': 'Congo', 'alpha2Code': 'CG'},
  {'name': 'Congo, Democratic Republic', 'alpha2Code': 'CD'},
  {'name': 'Cook Islands', 'alpha2Code': 'CK'},
  {'name': 'Costa Rica', 'alpha2Code': 'CR'},
  {'name': 'Cote DIvoire', 'alpha2Code': 'CI'},
  {'name': 'Croatia', 'alpha2Code': 'HR'},
  {'name': 'Cuba', 'alpha2Code': 'CU'},
  {'name': 'Cyprus', 'alpha2Code': 'CY'},
  {'name': 'Czech Republic', 'alpha2Code': 'CZ'},
  {'name': 'Denmark', 'alpha2Code': 'DK'},
  {'name': 'Djibouti', 'alpha2Code': 'DJ'},
  {'name': 'Dominica', 'alpha2Code': 'DM'},
  {'name': 'Dominican Republic', 'alpha2Code': 'DO'},
  {'name': 'Ecuador', 'alpha2Code': 'EC'},
  {'name': 'Egypt', 'alpha2Code': 'EG'},
  {'name': 'El Salvador', 'alpha2Code': 'SV'},
  {'name': 'Equatorial Guinea', 'alpha2Code': 'GQ'},
  {'name': 'Eritrea', 'alpha2Code': 'ER'},
  {'name': 'Estonia', 'alpha2Code': 'EE'},
  {'name': 'Ethiopia', 'alpha2Code': 'ET'},
  {'name': 'Falkland Islands (Malvinas)', 'alpha2Code': 'FK'},
  {'name': 'Faroe Islands', 'alpha2Code': 'FO'},
  {'name': 'Fiji', 'alpha2Code': 'FJ'},
  {'name': 'Finland', 'alpha2Code': 'FI'},
  {'name': 'France', 'alpha2Code': 'FR'},
  {'name': 'French Guiana', 'alpha2Code': 'GF'},
  {'name': 'French Polynesia', 'alpha2Code': 'PF'},
  {'name': 'French Southern Territories', 'alpha2Code': 'TF'},
  {'name': 'Gabon', 'alpha2Code': 'GA'},
  {'name': 'Gambia', 'alpha2Code': 'GM'},
  {'name': 'Georgia', 'alpha2Code': 'GE'},
  {'name': 'Germany', 'alpha2Code': 'DE'},
  {'name': 'Ghana', 'alpha2Code': 'GH'},
  {'name': 'Gibraltar', 'alpha2Code': 'GI'},
  {'name': 'Greece', 'alpha2Code': 'GR'},
  {'name': 'Greenland', 'alpha2Code': 'GL'},
  {'name': 'Grenada', 'alpha2Code': 'GD'},
  {'name': 'Guadeloupe', 'alpha2Code': 'GP'},
  {'name': 'Guam', 'alpha2Code': 'GU'},
  {'name': 'Guatemala', 'alpha2Code': 'GT'},
  {'name': 'Guernsey', 'alpha2Code': 'GG'},
  {'name': 'Guinea', 'alpha2Code': 'GN'},
  {'name': 'Guinea-Bissau', 'alpha2Code': 'GW'},
  {'name': 'Guyana', 'alpha2Code': 'GY'},
  {'name': 'Haiti', 'alpha2Code': 'HT'},
  {'name': 'Heard Island & Mcdonald Islands', 'alpha2Code': 'HM'},
  {'name': 'Holy See (Vatican City State)', 'alpha2Code': 'VA'},
  {'name': 'Honduras', 'alpha2Code': 'HN'},
  {'name': 'Hong Kong', 'alpha2Code': 'HK'},
  {'name': 'Hungary', 'alpha2Code': 'HU'},
  {'name': 'Iceland', 'alpha2Code': 'IS'},
  {'name': 'India', 'alpha2Code': 'IN'},
  {'name': 'Indonesia', 'alpha2Code': 'ID'},
  {'name': 'Iran, Islamic Republic Of', 'alpha2Code': 'IR'},
  {'name': 'Iraq', 'alpha2Code': 'IQ'},
  {'name': 'Ireland', 'alpha2Code': 'IE'},
  {'name': 'Isle Of Man', 'alpha2Code': 'IM'},
  {'name': 'Israel', 'alpha2Code': 'IL'},
  {'name': 'Italy', 'alpha2Code': 'IT'},
  {'name': 'Jamaica', 'alpha2Code': 'JM'},
  {'name': 'Japan', 'alpha2Code': 'JP'},
  {'name': 'Jersey', 'alpha2Code': 'JE'},
  {'name': 'Jordan', 'alpha2Code': 'JO'},
  {'name': 'Kazakhstan', 'alpha2Code': 'KZ'},
  {'name': 'Kenya', 'alpha2Code': 'KE'},
  {'name': 'Kiribati', 'alpha2Code': 'KI'},
  {'name': 'Korea', 'alpha2Code': 'KR'},
  {'name': 'Kuwait', 'alpha2Code': 'KW'},
  {'name': 'Kyrgyzstan', 'alpha2Code': 'KG'},
  {'name': 'Lao People\'s Democratic Republic', 'alpha2Code': 'LA'},
  {'name': 'Latvia', 'alpha2Code': 'LV'},
  {'name': 'Lebanon', 'alpha2Code': 'LB'},
  {'name': 'Lesotho', 'alpha2Code': 'LS'},
  {'name': 'Liberia', 'alpha2Code': 'LR'},
  {'name': 'Libyan Arab Jamahiriya', 'alpha2Code': 'LY'},
  {'name': 'Liechtenstein', 'alpha2Code': 'LI'},
  {'name': 'Lithuania', 'alpha2Code': 'LT'},
  {'name': 'Luxembourg', 'alpha2Code': 'LU'},
  {'name': 'Macao', 'alpha2Code': 'MO'},
  {'name': 'Macedonia', 'alpha2Code': 'MK'},
  {'name': 'Madagascar', 'alpha2Code': 'MG'},
  {'name': 'Malawi', 'alpha2Code': 'MW'},
  {'name': 'Malaysia', 'alpha2Code': 'MY'},
  {'name': 'Maldives', 'alpha2Code': 'MV'},
  {'name': 'Mali', 'alpha2Code': 'ML'},
  {'name': 'Malta', 'alpha2Code': 'MT'},
  {'name': 'Marshall Islands', 'alpha2Code': 'MH'},
  {'name': 'Martinique', 'alpha2Code': 'MQ'},
  {'name': 'Mauritania', 'alpha2Code': 'MR'},
  {'name': 'Mauritius', 'alpha2Code': 'MU'},
  {'name': 'Mayotte', 'alpha2Code': 'YT'},
  {'name': 'Mexico', 'alpha2Code': 'MX'},
  {'name': 'Micronesia, Federated States Of', 'alpha2Code': 'FM'},
  {'name': 'Moldova', 'alpha2Code': 'MD'},
  {'name': 'Monaco', 'alpha2Code': 'MC'},
  {'name': 'Mongolia', 'alpha2Code': 'MN'},
  {'name': 'Montenegro', 'alpha2Code': 'ME'},
  {'name': 'Montserrat', 'alpha2Code': 'MS'},
  {'name': 'Morocco', 'alpha2Code': 'MA'},
  {'name': 'Mozambique', 'alpha2Code': 'MZ'},
  {'name': 'Myanmar', 'alpha2Code': 'MM'},
  {'name': 'Namibia', 'alpha2Code': 'NA'},
  {'name': 'Nauru', 'alpha2Code': 'NR'},
  {'name': 'Nepal', 'alpha2Code': 'NP'},
  {'name': 'Netherlands', 'alpha2Code': 'NL'},
  {'name': 'Netherlands Antilles', 'alpha2Code': 'AN'},
  {'name': 'New Caledonia', 'alpha2Code': 'NC'},
  {'name': 'New Zealand', 'alpha2Code': 'NZ'},
  {'name': 'Nicaragua', 'alpha2Code': 'NI'},
  {'name': 'Niger', 'alpha2Code': 'NE'},
  {'name': 'Nigeria', 'alpha2Code': 'NG'},
  {'name': 'Niue', 'alpha2Code': 'NU'},
  {'name': 'Norfolk Island', 'alpha2Code': 'NF'},
  {'name': 'Northern Mariana Islands', 'alpha2Code': 'MP'},
  {'name': 'Norway', 'alpha2Code': 'NO'},
  {'name': 'Oman', 'alpha2Code': 'OM'},
  {'name': 'Pakistan', 'alpha2Code': 'PK'},
  {'name': 'Palau', 'alpha2Code': 'PW'},
  {'name': 'Palestinian Territory, Occupied', 'alpha2Code': 'PS'},
  {'name': 'Panama', 'alpha2Code': 'PA'},
  {'name': 'Papua New Guinea', 'alpha2Code': 'PG'},
  {'name': 'Paraguay', 'alpha2Code': 'PY'},
  {'name': 'Peru', 'alpha2Code': 'PE'},
  {'name': 'Philippines', 'alpha2Code': 'PH'},
  {'name': 'Pitcairn', 'alpha2Code': 'PN'},
  {'name': 'Poland', 'alpha2Code': 'PL'},
  {'name': 'Portugal', 'alpha2Code': 'PT'},
  {'name': 'Puerto Rico', 'alpha2Code': 'PR'},
  {'name': 'Qatar', 'alpha2Code': 'QA'},
  {'name': 'Reunion', 'alpha2Code': 'RE'},
  {'name': 'Romania', 'alpha2Code': 'RO'},
  {'name': 'Russian Federation', 'alpha2Code': 'RU'},
  {'name': 'Rwanda', 'alpha2Code': 'RW'},
  {'name': 'Saint Barthelemy', 'alpha2Code': 'BL'},
  {'name': 'Saint Helena', 'alpha2Code': 'SH'},
  {'name': 'Saint Kitts And Nevis', 'alpha2Code': 'KN'},
  {'name': 'Saint Lucia', 'alpha2Code': 'LC'},
  {'name': 'Saint Martin', 'alpha2Code': 'MF'},
  {'name': 'Saint Pierre And Miquelon', 'alpha2Code': 'PM'},
  {'name': 'Saint Vincent And Grenadines', 'alpha2Code': 'VC'},
  {'name': 'Samoa', 'alpha2Code': 'WS'},
  {'name': 'San Marino', 'alpha2Code': 'SM'},
  {'name': 'Sao Tome And Principe', 'alpha2Code': 'ST'},
  {'name': 'Saudi Arabia', 'alpha2Code': 'SA'},
  {'name': 'Senegal', 'alpha2Code': 'SN'},
  {'name': 'Serbia', 'alpha2Code': 'RS'},
  {'name': 'Seychelles', 'alpha2Code': 'SC'},
  {'name': 'Sierra Leone', 'alpha2Code': 'SL'},
  {'name': 'Singapore', 'alpha2Code': 'SG'},
  {'name': 'Slovakia', 'alpha2Code': 'SK'},
  {'name': 'Slovenia', 'alpha2Code': 'SI'},
  {'name': 'Solomon Islands', 'alpha2Code': 'SB'},
  {'name': 'Somalia', 'alpha2Code': 'SO'},
  {'name': 'South Africa', 'alpha2Code': 'ZA'},
  {'name': 'South Georgia And Sandwich Isl.', 'alpha2Code': 'GS'},
  {'name': 'Spain', 'alpha2Code': 'ES'},
  {'name': 'Sri Lanka', 'alpha2Code': 'LK'},
  {'name': 'Sudan', 'alpha2Code': 'SD'},
  {'name': 'Suriname', 'alpha2Code': 'SR'},
  {'name': 'Svalbard And Jan Mayen', 'alpha2Code': 'SJ'},
  {'name': 'Swaziland', 'alpha2Code': 'SZ'},
  {'name': 'Sweden', 'alpha2Code': 'SE'},
  {'name': 'Switzerland', 'alpha2Code': 'CH'},
  {'name': 'Syrian Arab Republic', 'alpha2Code': 'SY'},
  {'name': 'Taiwan', 'alpha2Code': 'TW'},
  {'name': 'Tajikistan', 'alpha2Code': 'TJ'},
  {'name': 'Tanzania', 'alpha2Code': 'TZ'},
  {'name': 'Thailand', 'alpha2Code': 'TH'},
  {'name': 'Timor-Leste', 'alpha2Code': 'TL'},
  {'name': 'Togo', 'alpha2Code': 'TG'},
  {'name': 'Tokelau', 'alpha2Code': 'TK'},
  {'name': 'Tonga', 'alpha2Code': 'TO'},
  {'name': 'Trinidad And Tobago', 'alpha2Code': 'TT'},
  {'name': 'Tunisia', 'alpha2Code': 'TN'},
  {'name': 'Turkey', 'alpha2Code': 'TR'},
  {'name': 'Turkmenistan', 'alpha2Code': 'TM'},
  {'name': 'Turks And Caicos Islands', 'alpha2Code': 'TC'},
  {'name': 'Tuvalu', 'alpha2Code': 'TV'},
  {'name': 'Uganda', 'alpha2Code': 'UG'},
  {'name': 'Ukraine', 'alpha2Code': 'UA'},
  {'name': 'United Arab Emirates', 'alpha2Code': 'AE'},
  {'name': 'United Kingdom', 'alpha2Code': 'GB'},
  {'name': 'United States', 'alpha2Code': 'US'},
  {'name': 'United States Outlying Islands', 'alpha2Code': 'UM'},
  {'name': 'Uruguay', 'alpha2Code': 'UY'},
  {'name': 'Uzbekistan', 'alpha2Code': 'UZ'},
  {'name': 'Vanuatu', 'alpha2Code': 'VU'},
  {'name': 'Venezuela', 'alpha2Code': 'VE'},
  {'name': 'Vietnam', 'alpha2Code': 'VN'},
  {'name': 'Virgin Islands, British', 'alpha2Code': 'VG'},
  {'name': 'Virgin Islands, U.S.', 'alpha2Code': 'VI'},
  {'name': 'Wallis And Futuna', 'alpha2Code': 'WF'},
  {'name': 'Western Sahara', 'alpha2Code': 'EH'},
  {'name': 'Yemen', 'alpha2Code': 'YE'},
  {'name': 'Zambia', 'alpha2Code': 'ZM'},
  {'name': 'Zimbabwe', 'alpha2Code': 'ZW'},
];

export const alpha2CodeMap = {
  AF: {label: 'Afghanistan'},
  AX: {label: 'Aland Islands'},
  AL: {label: 'Albania'},
  DZ: {label: 'Algeria'},
  AS: {label: 'American Samoa'},
  AD: {label: 'Andorra'},
  AO: {label: 'Angola'},
  AI: {label: 'Anguilla'},
  AQ: {label: 'Antarctica'},
  AG: {label: 'Antigua And Barbuda'},
  AR: {label: 'Argentina'},
  AM: {label: 'Armenia'},
  AW: {label: 'Aruba'},
  AU: {label: 'Australia'},
  AT: {label: 'Austria'},
  AZ: {label: 'Azerbaijan'},
  BS: {label: 'Bahamas'},
  BD: {label: 'Bangladesh'},
  BB: {label: 'Barbados'},
  BY: {label: 'Belarus'},
  BE: {label: 'Belgium'},
  BZ: {label: 'Belize'},
  BJ: {label: 'Benin'},
  BH: {label: 'Bahrain'},
  BM: {label: 'Bermuda'},
  BT: {label: 'Bhutan'},
  BO: {label: 'Bolivia'},
  BA: {label: 'Bosnia And Herzegovina'},
  BW: {label: 'Botswana'},
  BV: {label: 'Bouvet Island'},
  BR: {label: 'Brazil'},
  IO: {label: 'British Indian Ocean Territory'},
  BN: {label: 'Brunei Darussalam'},
  BG: {label: 'Bulgaria'},
  BF: {label: 'Burkina Faso'},
  BI: {label: 'Burundi'},
  KH: {label: 'Cambodia'},
  CM: {label: 'Cameroon'},
  CA: {label: 'Canada'},
  CV: {label: 'Cape Verde'},
  KY: {label: 'Cayman Islands'},
  CF: {label: 'Central African Republic'},
  TD: {label: 'Chad'},
  CL: {label: 'Chile'},
  CN: {label: 'China'},
  CX: {label: 'Christmas Island'},
  CC: {label: 'Cocos (Keeling) Islands'},
  CO: {label: 'Colombia'},
  KM: {label: 'Comoros'},
  CG: {label: 'Congo'},
  CD: {label: 'Congo, Democratic Republic'},
  CK: {label: 'Cook Islands'},
  CR: {label: 'Costa Rica'},
  CI: {label: 'Cote DIvoire'},
  HR: {label: 'Croatia'},
  CU: {label: 'Cuba'},
  CY: {label: 'Cyprus'},
  CZ: {label: 'Czech Republic'},
  DK: {label: 'Denmark'},
  DJ: {label: 'Djibouti'},
  DM: {label: 'Dominica'},
  DO: {label: 'Dominican Republic'},
  EC: {label: 'Ecuador'},
  EG: {label: 'Egypt'},
  SV: {label: 'El Salvador'},
  GQ: {label: 'Equatorial Guinea'},
  ER: {label: 'Eritrea'},
  EE: {label: 'Estonia'},
  ET: {label: 'Ethiopia'},
  FK: {label: 'Falkland Islands (Malvinas)'},
  FO: {label: 'Faroe Islands'},
  FJ: {label: 'Fiji'},
  FI: {label: 'Finland'},
  FR: {label: 'France'},
  GF: {label: 'French Guiana'},
  PF: {label: 'French Polynesia'},
  TF: {label: 'French Southern Territories'},
  GA: {label: 'Gabon'},
  GM: {label: 'Gambia'},
  GE: {label: 'Georgia'},
  DE: {label: 'Germany'},
  GH: {label: 'Ghana'},
  GI: {label: 'Gibraltar'},
  GR: {label: 'Greece'},
  GL: {label: 'Greenland'},
  GD: {label: 'Grenada'},
  GP: {label: 'Guadeloupe'},
  GU: {label: 'Guam'},
  GT: {label: 'Guatemala'},
  GG: {label: 'Guernsey'},
  GN: {label: 'Guinea'},
  GW: {label: 'Guinea-Bissau'},
  GY: {label: 'Guyana'},
  HT: {label: 'Haiti'},
  HM: {label: 'Heard Island & Mcdonald Islands'},
  VA: {label: 'Holy See (Vatican City State)'},
  HN: {label: 'Honduras'},
  HK: {label: 'Hong Kong'},
  HU: {label: 'Hungary'},
  IS: {label: 'Iceland'},
  IN: {label: 'India'},
  ID: {label: 'Indonesia'},
  IR: {label: 'Iran'},
  IQ: {label: 'Iraq'},
  IE: {label: 'Ireland'},
  IM: {label: 'Isle Of Man'},
  IL: {label: 'Israel'},
  IT: {label: 'Italy'},
  JM: {label: 'Jamaica'},
  JP: {label: 'Japan'},
  JE: {label: 'Jersey'},
  JO: {label: 'Jordan'},
  KZ: {label: 'Kazakhstan'},
  KE: {label: 'Kenya'},
  KI: {label: 'Kiribati'},
  KR: {label: 'Korea'},
  KW: {label: 'Kuwait'},
  KG: {label: 'Kyrgyzstan'},
  LA: {label: 'Lao People\'s Democratic Republic'},
  LV: {label: 'Latvia'},
  LB: {label: 'Lebanon'},
  LS: {label: 'Lesotho'},
  LR: {label: 'Liberia'},
  LY: {label: 'Libyan Arab Jamahiriya'},
  LI: {label: 'Liechtenstein'},
  LT: {label: 'Lithuania'},
  LU: {label: 'Luxembourg'},
  MO: {label: 'Macao'},
  MK: {label: 'Macedonia'},
  MG: {label: 'Madagascar'},
  MW: {label: 'Malawi'},
  MY: {label: 'Malaysia'},
  MV: {label: 'Maldives'},
  ML: {label: 'Mali'},
  MT: {label: 'Malta'},
  MH: {label: 'Marshall Islands'},
  MQ: {label: 'Martinique'},
  MR: {label: 'Mauritania'},
  MU: {label: 'Mauritius'},
  YT: {label: 'Mayotte'},
  MX: {label: 'Mexico'},
  FM: {label: 'Micronesia'},
  MD: {label: 'Moldova'},
  MC: {label: 'Monaco'},
  MN: {label: 'Mongolia'},
  ME: {label: 'Montenegro'},
  MS: {label: 'Montserrat'},
  MA: {label: 'Morocco'},
  MZ: {label: 'Mozambique'},
  MM: {label: 'Myanmar'},
  NA: {label: 'Namibia'},
  NR: {label: 'Nauru'},
  NP: {label: 'Nepal'},
  NL: {label: 'Netherlands'},
  AN: {label: 'Netherlands Antilles'},
  NC: {label: 'New Caledonia'},
  NZ: {label: 'New Zealand'},
  NI: {label: 'Nicaragua'},
  NE: {label: 'Niger'},
  NG: {label: 'Nigeria'},
  NU: {label: 'Niue'},
  NF: {label: 'Norfolk Island'},
  MP: {label: 'Northern Mariana Islands'},
  NO: {label: 'Norway'},
  OM: {label: 'Oman'},
  PK: {label: 'Pakistan'},
  PW: {label: 'Palau'},
  PA: {label: 'Panama'},
  PG: {label: 'Papua New Guinea'},
  PY: {label: 'Paraguay'},
  PE: {label: 'Peru'},
  PH: {label: 'Philippines'},
  PN: {label: 'Pitcairn'},
  PL: {label: 'Poland'},
  PT: {label: 'Portugal'},
  PR: {label: 'Puerto Rico'},
  QA: {label: 'Qatar'},
  RE: {label: 'Reunion'},
  RO: {label: 'Romania'},
  RU: {label: 'Russian Federation'},
  RW: {label: 'Rwanda'},
  BL: {label: 'Saint Barthelemy'},
  SH: {label: 'Saint Helena'},
  KN: {label: 'Saint Kitts And Nevis'},
  LC: {label: 'Saint Lucia'},
  MF: {label: 'Saint Martin'},
  PM: {label: 'Saint Pierre And Miquelon'},
  VC: {label: 'Saint Vincent And Grenadines'},
  WS: {label: 'Samoa'},
  SM: {label: 'San Marino'},
  ST: {label: 'Sao Tome And Principe'},
  SA: {label: 'Saudi Arabia'},
  SN: {label: 'Senegal'},
  RS: {label: 'Serbia'},
  SC: {label: 'Seychelles'},
  SL: {label: 'Sierra Leone'},
  SG: {label: 'Singapore'},
  SK: {label: 'Slovakia'},
  SI: {label: 'Slovenia'},
  SB: {label: 'Solomon Islands'},
  SO: {label: 'Somalia'},
  ZA: {label: 'South Africa'},
  GS: {label: 'South Georgia And Sandwich Isl.'},
  ES: {label: 'Spain'},
  LK: {label: 'Sri Lanka'},
  SD: {label: 'Sudan'},
  SR: {label: 'Suriname'},
  SJ: {label: 'Svalbard And Jan Mayen'},
  SZ: {label: 'Swaziland'},
  SE: {label: 'Sweden'},
  CH: {label: 'Switzerland'},
  SY: {label: 'Syrian Arab Republic'},
  TW: {label: 'Taiwan'},
  TJ: {label: 'Tajikistan'},
  TZ: {label: 'Tanzania'},
  TH: {label: 'Thailand'},
  TL: {label: 'Timor-Leste'},
  TG: {label: 'Togo'},
  TK: {label: 'Tokelau'},
  TO: {label: 'Tonga'},
  TT: {label: 'Trinidad And Tobago'},
  TN: {label: 'Tunisia'},
  TR: {label: 'Turkey'},
  TM: {label: 'Turkmenistan'},
  TC: {label: 'Turks And Caicos Islands'},
  TV: {label: 'Tuvalu'},
  UG: {label: 'Uganda'},
  UA: {label: 'Ukraine'},
  AE: {label: 'United Arab Emirates'},
  GB: {label: 'United Kingdom'},
  US: {label: 'United States'},
  UM: {label: 'United States Outlying Islands'},
  UY: {label: 'Uruguay'},
  UZ: {label: 'Uzbekistan'},
  VU: {label: 'Vanuatu'},
  VE: {label: 'Venezuela'},
  VN: {label: 'Vietnam'},
  VG: {label: 'Virgin Islands, British'},
  VI: {label: 'Virgin Islands, U.S.'},
  WF: {label: 'Wallis And Futuna'},
  EH: {label: 'Western Sahara'},
  YE: {label: 'Yemen'},
  ZM: {label: 'Zambia'},
  ZW: {label: 'Zimbabwe'},
};
