import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {List, ListItem, ListItemIcon, ListItemText, Collapse} from '@material-ui/core';
//
import {ExpandMoreIcon, ExpandLessIcon} from '../../components/common/icons';
import {menu} from './menuConfig';

const MenuItem = ({menuItem}) => {
  const Component = menuItem.items.length ? MultiLevel : SingleLevel;
  return <Component item={menuItem} />;
};

const SingleLevel = ({item}) => {
  return (
    <Link to={item.link} style={{textDecoration: 'none'}}>
      <ListItem button>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItem>
    </Link>
  );
};

const MultiLevel = ({item}) => {
  const {items: children} = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Link to={item.link} style={{textDecoration: 'none'}}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} menuItem={child} />
              ))}
        </List>
      </Collapse>
    </Link>
  );
};

export const Sidebar = ({user}) => {
  return (
    menu
      .filter((item) => item.accessRoles.includes(user.role))
      .map((item, key) => <MenuItem key={key} menuItem={item} />)
  );
};

export default Sidebar;
