import React, {useState, useEffect} from 'react';
import {Box} from '@mui/material';
import {Paper} from '@material-ui/core';
import {DataGrid} from '@mui/x-data-grid';
//
import FilterPanel from './FilterPanel';
import Title from 'components/common/Title';
import Loader from 'components/common/Loader';
import {CLIENTS_TRANSACTIONS_HISTORY_COLUMN_DEFS} from 'utils/tables/columns/transactionsHistory';
import {getClientTransactions} from 'actions/index';
import useStyles from '../transactions.styles';

const TransactionHistoryTable = (props) => {
    // hooks
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const [isPending, setPending] = useState(false);

    useEffect(() => {
      const fetchTransactionsData = async () => {
        const transactionList = await getClientTransactions();
        setTransactions(transactionList);
      };

      fetchTransactionsData();
    }, []);

    const applyFilters = async (filters) => {
      const results = await getClientTransactions(filters);
      setTransactions(results);
    };

    return (
      <Paper>
        <div style={{width: '100%', marginTop: '15px', marginBottom: '15px'}}>
          <Box sx={{pt: 2, px: 2}}>
            <Title>Clients Transaction History</Title>
          </Box>
          <Box>
            <FilterPanel
              clientOptionsData={props.clientOptionsData}
              publisherOptionsData={props.publisherOptionsData}
              endpointOptionsData={props.endpointOptionsData}
              applyFilters={applyFilters}
            />
          </Box>
          <Box sx={{pt: 2, px: 2, paddingBottom: '15px'}}>
            <Loader isPending={isPending} />

            <DataGrid
              rows={transactions}
              columns={CLIENTS_TRANSACTIONS_HISTORY_COLUMN_DEFS}
              className={`${classes.root}`}
              sx={{
                'boxShadow': 2,
                'border': 0.5,
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
              }}
            />
          </Box>
        </div>
      </Paper>
    );
};

export default TransactionHistoryTable;
