import axios from 'axios';

// Action types
import {LOAD_PLATFORM_SETTINGS, UPDATE_UNASSIGNED_BALANCE} from './types';

export const loadPlatformSettings = () => async (dispatch) => {
  try {
    const {data} = await axios.get(`/v1/whitelabel/platform-settings`);
    dispatch({
      type: LOAD_PLATFORM_SETTINGS,
      payload: {balance: data},
    });
  } catch (e) {
    console.error(e);
  }
}

export const syncPlatformUnassignedBalance = (deposit) => (dispatch) => {
  dispatch({
    type: UPDATE_UNASSIGNED_BALANCE,
    payload: {deposit},
  });
};
