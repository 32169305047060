import {
  Grid,
  makeStyles,
  Paper,
  TextareaAutosize, Switch, FormControlLabel,
} from '@material-ui/core';
import {Form, Field} from 'react-final-form';
import {TextField} from 'mui-rff';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React, {useEffect} from 'react';
import {validate} from 'utils/validate';
import Box from '@mui/material/Box';

import {
  getAdDomainByID,
  updateAdDomainByID,
} from 'actions/index';
import {reloadCDNNginx} from 'actions/reload';
import ButtonSubmit from 'components/common/ButtonSubmit';
import {showErrorNotification, showSuccessNotification} from 'utils/common';

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: '100%',
  },
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function AdDomain(props) {
  const {isUpdate, match} = props;
  const classes = useStyles();
  const [adDomainInfo, setAdDomainInfo] = React.useState(null);
  const [isPending, setIsPending] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (isUpdate) {
        const data = await getAdDomainByID(match.params.id);
        if (data) {
          setAdDomainInfo(data.adDomains[0] || {});
        }
      }
    };
    fetchData();
  }, []);

  const onReload = async (values) => {
    setIsPending(true);
    const obj = {
      domain: values.domain,
      isHTTPS: values.isHTTPS,
      sslCert: values.sslCert,
      sslKey: values.sslKey,
      isCDN: true,
    };
    const result = await reloadCDNNginx(obj);
    if (result.status === 200) {
      showSuccessNotification('Success!');
    } else {
      showErrorNotification(`Server error: ${result.message}`);
    }
    setIsPending(false);
  };

  const onSubmit = async (values) => {
    try {
      await updateAdDomainByID(match.params.id, values);
      showSuccessNotification('Success!');
    } catch (e) {
      showErrorNotification('Server error');
    }
  };

  return (
    <React.Fragment>
      {
        adDomainInfo ?
          <Form
            onSubmit={(values) => onSubmit(values, {...props})}
            initialValues={adDomainInfo}
            render={({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate>
                <Paper style={{padding: 16}}>
                  <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item xs={6} className={classes.flexGridItem}>
                      <TextField
                        name='domain'
                        label='Domain'
                        margin='none'
                        placeholder='domain.com'
                      />
                      <CopyToClipboard
                        text={values.domain}
                        onCopy={() => showSuccessNotification('Copied!')}
                      >
                        <IconButton
                          size='small'
                          color='primary'
                          aria-label='copy'
                        >
                          <FileCopyOutlinedIcon fontSize='small' />
                        </IconButton>
                      </CopyToClipboard>
                    </Grid>

                    <Grid item xs={6} className={classes.flexGridItem}>
                      <TextField
                        name='cname'
                        label='Cname'
                        margin='none'
                        defaultValue={`ad-${values.wlid}.ubidex.bid`}
                      />
                      <CopyToClipboard
                        text={`ui-${values.wlid}.ubidex.bid`}
                        onCopy={() => showSuccessNotification('Copied!')}
                      >
                        <IconButton
                          size='small'
                          color='primary'
                          aria-label='copy'
                        >
                          <FileCopyOutlinedIcon fontSize='small' />
                        </IconButton>
                      </CopyToClipboard>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='isHTTPS'
                        component={(props) => (
                          <FormControlLabel
                            control={
                              <Switch
                                name={props.input.name}
                                checked={props.input.value}
                                onChange={props.input.onChange}
                              />
                            }
                            label='https'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='sslCert'
                        component={(props) => (
                          <TextareaAutosize
                            disabled={!values.isHTTPS}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            aria-label='minimum height'
                            minRows={10}
                            placeholder='Cert'
                            style={{width: '100%', height: 50}}
                          />)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='sslKey'
                        component={(props) => (
                          <TextareaAutosize
                            disabled={!values.isHTTPS}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            aria-label='minimum height'
                            minRows={10}
                            placeholder='Key'
                            style={{width: '100%', height: 50}}
                          />)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <ButtonSubmit
                          // disabled
                          name='reload'
                          onSubmit={() => onReload(values)}
                          isPending={isPending}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item style={{marginTop: 16}}>
                      <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <ButtonSubmit
                          name='save'
                          onSubmit={handleSubmit}
                          isPending={submitting}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          /> :
          null
      }
    </React.Fragment>
  );
}
