import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';

import Footer from '../components/footer';
import Sidebar from '../components/sidebar';
import SignIn from '../components/form/login';
import {getToken} from 'utils/cookiesUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}));

export default function IndexRouter(props) {
  const classes = useStyles();
  const isAuthenticated = !!getToken();

  return (
    <div className={classes.root}>
      <CssBaseline />
      {
        !isAuthenticated ?
          <Switch>
            <Route path='/login' component={SignIn} />
            <Redirect exact from='/*' to='/login' />
          </Switch> :
          <Sidebar {...props} />
      }
      <Footer />
    </div>
  );
}
