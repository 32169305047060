import React from 'react';
import {TextField} from 'mui-rff';
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';

export const createFormFields = (values, isUpdate) => {
  return [
    {
      size: 6,
      isCopy: true,
      name: 'name',
      field: (
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          required
        />
      ),
    },
    {
      size: 12,
      field: <TextField name='note' multiline label='Notes' margin='none' />,
    },
  ];
};

export const IntegrationTypeRadioControls = ({input, ...rest}) => {
  return (
    <Box sx={{marginTop: '20px'}}>
      <FormControl>
        <FormLabel id='integrationType'>Integration Type</FormLabel>
        <RadioGroup
          {...rest}
          row
          name={input.name}
          value={input.value}
          onChange={(event, value) => input.onChange(value)}
        >
          <FormControlLabel value={false} control={<Radio />} label='Manual Integration' />
          <FormControlLabel value control={<Radio />} label='Gateway' />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export const PaymentTypeRadioControls = ({input, ...rest}) => {
  return (
    <Box sx={{marginTop: '20px'}}>
      <FormControl>
        <FormLabel id='paymentType'>Payment Type</FormLabel>
        <RadioGroup
          {...rest}
          row
          name={input.name}
          value={input.value}
          onChange={(event, value) => input.onChange(value)}
        >
          <FormControlLabel value='PREPAY' control={<Radio />} label='Prepay' />
          <FormControlLabel value='POSTPAY' control={<Radio />} label='Postpay' />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
