import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import {createSvgIcon} from '@mui/material/utils';

const ExportIcon = createSvgIcon(
  <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z' />,
    'SaveAlt',
);

export {
  MenuIcon,
  DashboardIcon,
  PeopleIcon,
  BarChartIcon,
  ChevronLeftIcon,
  NotificationsIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  ExitToAppIcon,
  CurrencyExchangeIcon,
  ExportIcon,
  CheckCircleOutlineIcon,
  DoneIcon,
  CloseIcon,
};
