import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import querystring from 'querystring';

export const loadDailyReport = async (filters = {}) => {
  if (!filters.startDate) {
    filters.startDate = moment().format('YYYY-MM-DD');
  }
  if (!filters.endDate) {
    filters.endDate = moment().format('YYYY-MM-DD');
  }

  filters.reportsType = 'DAILY';
  // filters.groupBy = 'createdDate,wlid,uPublisherId';
  filters.addColumns = 'imp,click';

  for (const key in filters) {
    if (_.isArray(filters[key])) {
      if (filters[key].length) {
        filters[key] = JSON.stringify(filters[key]);
      } else {
        delete filters[key];
      }
    }
  }

  const apiUrl = `/v1/reports?${querystring.stringify(filters)}`;
  try {
    const {data} = await axios.get(apiUrl);

    return {rows: data.data, count: data.count};
  } catch (err) {
    return {};
  }
};

export const getWlIdDropdownValues = async () => {
  const apiUrl = `/v1/reports/wlid-list`;
  try {
    const {data} = await axios.get(apiUrl);

    return data ?
        data.map((item) => ({value: item.id, label: `(${item.wlid}) ${item.platformName}`, wlid: item.wlid}))
            .sort((a, b) => b.wlid - a.wlid) :
        [];
  } catch (err) {
    return [];
  }
};

export const getPublisherDropdownValues = async () => {
  const apiUrl = `/v1/reports/publisher-list`;
  try {
    const {data} = await axios.get(apiUrl);

    return data ?
        data.map((item) => ({value: item.id, label: `(${item.id}) ${item.name}`})).sort((a, b) => b.value - a.value) :
        [];
  } catch (err) {
    return [];
  }
};

export const getEndpointDropdownValues = async () => {
  const apiUrl = `/v1/reports/endpoint-list`;
  try {
    const {data} = await axios.get(apiUrl);

    return data ?
        data.map((item) => ({value: item.id, label: `(${item.id}) ${item.name}`})).sort((a, b) => b.value - a.value) :
        [];
  } catch (err) {
    return [];
  }
};
