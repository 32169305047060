import React from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {Link} from '@material-ui/core';

const dateFns = new DateFnsUtils();

export const CLIENTS_TRANSACTIONS_HISTORY_COLUMN_DEFS = [
  {
    field: 'client',
    headerName: 'Client',
    width: 180,
  },
  {
    field: 'transactionNumber',
    headerName: 'Transaction Id',
    flex: 1,
  },
  {
    field: 'deposit',
    headerName: 'Deposit ($)',
    flex: 1,
  },
  {
    field: 'commissionAmount',
    headerName: 'Commission, ($)',
    flex: 1,
  },
  {
    field: 'commission',
    headerName: 'Commission, (%)',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Amount ($)',
    flex: 1,
  },
  // {
  //   field: 'paymentType',
  //   headerName: 'Payment Type',
  //   flex: 1,
  //   renderCell: (params) => {
  //     const classStatus = clsx('payment', {
  //       manual: params.value.toLowerCase() === 'manual',
  //       active: params.value.toLowerCase() === 'active',
  //     });
  //
  //     return (<span className={classStatus}>{params.value}</span>);
  //   },
  // },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: (params) => {
      const classStatus = clsx('status', {
        succeeded: params.value.toLowerCase() === 'succeeded',
        pending: params.value.toLowerCase() === 'pending',
        new: params.value.toLowerCase() === 'new',
      });

      return (<span className={classStatus}>{params.value}</span>);
    },
  },
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    width: 180,
    valueFormatter: (params) => dateFns.format(new Date(params.value), 'yyyy-MM-dd HH:mm:ss'),
  },
];

export const TRANSACTIONS_HISTORY_COLUMN_DEFS = [
  {
    field: 'id',
    headerName: 'Id',
    width: 90,
  },
  {
    field: 'transactionNumber',
    headerName: 'Transaction Number',
    flex: 1,
  },
  {
    field: 'deposit',
    headerName: 'Deposit ($)',
    flex: 1,
  },
  {
    field: 'commission',
    headerName: 'Commission (%)',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Amount ($)',
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => {
      const classStatus = clsx('status', {
        new: params.value.toLowerCase() === 'new',
        succeeded: params.value.toLowerCase() === 'succeeded',
        pending: params.value.toLowerCase() === 'pending',
      });

      return (<span className={classStatus}>{params.value}</span>);
    },
  },
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    flex: 1,
    valueFormatter: (params) => dateFns.format(new Date(params.value), 'yyyy-MM-dd HH:mm:ss'),
  },
];

export const UGW_TRANSACTIONS_HISTORY_COLUMN_DEFS = [
  {
    field: 'id',
    headerName: 'Id',
  },
  {
    field: 'paymentNumber',
    headerName: 'Payment Number',
    renderCell: ({row}) => {
      return <Link style={{cursor: 'pointer', textDecoration: 'none'}}>{row.paymentNumber}</Link>;
    },
    flex: 1,
  },
  {
    field: 'ugwPublisher',
    headerName: 'Publisher',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Deposit ($)',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => {
      const classStatus = clsx('status', {
        completed: params.value.toLowerCase() === 'completed',
        approved: params.value.toLowerCase() === 'approved',
        pending: params.value.toLowerCase() === 'pending',
      });

      return (<span className={classStatus}>{params.value}</span>);
    },
  },
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    width: 180,
    valueFormatter: (params) => dateFns.format(new Date(params.value), 'yyyy-MM-dd HH:mm:ss'),
  },
];

export const UGW_ENDPOINT_TRANSACTIONS_HISTORY_COLUMN_DEFS = [
  {
    field: 'id',
    headerName: 'Id',
    width: 80,
  },
  {
    field: 'transactionNumber',
    headerName: 'Transaction',
    flex: 1,
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
  },
  {
    field: 'ugwPublisher',
    headerName: 'Publisher',
    flex: 1,
  },
  {
    field: 'ugwPublisherEndpoint',
    headerName: 'Endpoint',
    flex: 1,
  },
  {
    field: 'clientPublisher',
    headerName: 'Client Publisher',
    flex: 1,
  },
  {
    field: 'deposit',
    headerName: 'Deposit ($)',
    flex: 1,
  },
  // {
  //   field: 'type',
  //   headerName: 'Type',
  //   flex: 1,
  //   renderCell: (params) => {
  //     const classStatus = clsx('type', {
  //       inbound_transfer: params.value.toLowerCase() === 'inbound_transfer',
  //       payout: params.value.toLowerCase() === 'payout',
  //       payout_cancel: params.value.toLowerCase() === 'payout_cancel',
  //       refund: params.value.toLowerCase() === 'refund',
  //     });
  //
  //     return (<span className={classStatus}>{params.value}</span>);
  //   },
  // },
  // {
  //   field: 'paymentType',
  //   headerName: 'Payment Type',
  //   flex: 1,
  //   renderCell: (params) => {
  //     const classStatus = clsx('payment', {
  //       manual: params.value.toLowerCase() === 'manual',
  //     });
  //
  //     return (<span className={classStatus}>{params.value}</span>);
  //   },
  // },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => {
      const classStatus = clsx('status', {
        new: params.value.toLowerCase() === 'new',
        succeeded: params.value.toLowerCase() === 'succeeded',
        canceled: params.value.toLowerCase() === 'canceled',
        pending: params.value.toLowerCase() === 'pending',
      });

      return (<span className={classStatus}>{params.value}</span>);
    },
  },
  {
    field: 'statusReason',
    headerName: 'Status Reason',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    width: 180,
    valueFormatter: (params) => dateFns.format(new Date(params.value), 'yyyy-MM-dd HH:mm:ss'),
  },
];

export const PAYMENT_TRANSACTIONS_COLUMN_DEFS = [
  {
    field: 'transactionNumber',
    headerName: 'Transaction',
    flex: 1,
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
  },
  {
    field: 'clientPublisher',
    headerName: 'Client Publisher',
    flex: 1,
  },
  {
    field: 'deposit',
    headerName: 'Deposit ($)',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    width: 180,
    sortable: true,
    valueFormatter: (params) => dateFns.format(new Date(params.value), 'yyyy-MM-dd HH:mm:ss'),
  },
];
