import React, {Fragment, useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//
import FilterPanel from 'components/reports/FilterPanel';
import ReportTable from 'components/reports/ReportTable';
import Title from 'components/common/Title';
import {loadDailyReport} from 'actions/reports';
import Loader from 'components/common/Loader';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 18,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    summary: {
        height: 70,
    },
}));

const ReportsPage = (props) => {
    const classes = useStyles();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    });
    const [currentFilters, setCurrentFilters] = useState({
        groupBy: 'createdDate',
    });
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [isRequestPending, setRequestPending] = useState(false);

    useEffect(() => {
      (async () => {
          const reportProps = {
              ...currentFilters,
              page: paginationModel.page,
          };

          setRequestPending(true);

          const data = await loadDailyReport(reportProps);

          setRows(data.rows);
          setCount(data.count);
          setRequestPending(false);
      })();
    }, [currentFilters, paginationModel]);

    const applyFilters = (filtersProps) => {
      setCurrentFilters(filtersProps);
      setPaginationModel((prev) => ({...prev, page: 0}));
    };

    const handlePaginationChange = async (paginationNext) => {
      setPaginationModel((prev) => ({...prev, ...paginationNext}));
    };

    return (
      <Fragment>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='95%' className={classes.container}>
          <Paper className={classes.paper}>
            <Grid item xs={4} md={6} lg={6}>
              <Title>Reports</Title>
            </Grid>

            <FilterPanel
              applyFilters={applyFilters}
              isRequestPending={isRequestPending}
            />
          </Paper>
          <Paper>
            <ReportTable
              groupBy={currentFilters.groupBy}
              rowData={rows}
              count={count}
              paginationModel={paginationModel}
              onPaginationChange={handlePaginationChange}
              isRequestPending={isRequestPending}
            />
          </Paper>
        </Container>
      </Fragment>
  );
};

export default ReportsPage;
