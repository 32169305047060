import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    colCell: {
        flex: 1,
        minWidth: '1px !important',
        maxWidth: 'none !important',
    },
    flexGridItem: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '30px',
        marginTop: '15px',
    },

    root: {
        '& .type.inbound_transfer': {
            backgroundColor: '#1578b6',
        },
        '& .type.payout': {
            backgroundColor: '#1578b6',
        },
        '& .type.payout_cancel': {
            backgroundColor: '#ad210f',
        },
        '& .type.refund': {
            backgroundColor: '#f6ab2e',
        },
        '& .payment.manual': {
            backgroundColor: '#1578b6',
        },
        '& .status.new': {
            backgroundColor: '#1578b6',
        },
        '& .status.approved': {
            backgroundColor: '#1578b6',
        },
        '& .status.completed': {
            backgroundColor: '#3ed436',
        },
        '& .status.succeeded': {
            backgroundColor: '#3ed436',
        },
        '& .status.pending': {
            backgroundColor: '#f6ab2e',
        },
        '& .status.canceled': {
            backgroundColor: '#ad210f',
        },
        '&.MuiBox-root': {
            paddingBottom: '5px',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
    },
}));

export default useStyles;
