import {
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import {Form} from 'react-final-form';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React, {useEffect} from 'react';
import {createFormFields} from './fields';
import {NEW, PAYMENT_ALERT} from '../../../../constants';
import {validate} from 'utils/validate';
import {useSnackbar} from 'notistack';
import {createWhiteLabel, getGeneralByID, updateWhiteLabel} from 'actions/index';
import config from '../../../../config';
import Box from '@mui/material/Box';
import ButtonSubmit from 'components/common/ButtonSubmit';
import {Checkboxes, DatePicker, TextField} from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const initial = {
  paymentAlert: PAYMENT_ALERT,
  status: NEW,
};

export default function General(props) {
  const {isUpdate, match} = props;
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [generalInfo, setGeneralInfo] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (isUpdate) {
        const data = await getGeneralByID(match.params.id);
        setGeneralInfo(data);
      } else {
        setGeneralInfo(initial);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async (values, props) => {
    const {isUpdate} = props;

    try {
      if (isUpdate) {
        await updateWhiteLabel(values);
      } else {
        if (!values.domain) {
          values.domain = config.subDomain.replace('{TITLE}', values.platformName);
        }
        await createWhiteLabel(values);
      }
      enqueueSnackbar('Success!', {variant: 'success'});
    } catch (e) {
      enqueueSnackbar('Server error!', {variant: 'error'});
    }
  };

  return (
    <React.Fragment>
      {
      generalInfo ?
        <Form
          onSubmit={(values) => onSubmit(values, {...props})}
          initialValues={generalInfo}
          validate={validate}
          render={({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{padding: 16}}>
                <Grid container alignItems='flex-start' spacing={2}>
                  {
                    createFormFields(values, isUpdate).map((item, idx) => (
                      <Grid item xs={item.size} key={idx} className={classes.flexGridItem}>
                        {item.field}
                        {
                          item.isCopy ?
                            <CopyToClipboard
                              text={values[item.name]}
                              onCopy={() => enqueueSnackbar('Copied!', {variant: 'success'})}
                            >
                              <IconButton
                                size='small'
                                color='primary'
                                aria-label='copy'
                              >
                                <FileCopyOutlinedIcon fontSize='small' />
                              </IconButton>
                            </CopyToClipboard> : null
                        }
                      </Grid>
                    ))
                  }
                </Grid>

                <Grid item xs={12} className={classes.flexGridItem}>
                  <Checkboxes
                    name='isSSLAppAlert'
                    formControlProps={{margin: 'none'}}
                    data={{label: 'SSL App Domain Notification', value: false}}
                  />
                </Grid>

                <Grid item xs={12} className={classes.flexGridItem}>
                  <Checkboxes
                    name='isBetaFunctionality'
                    formControlProps={{margin: 'none'}}
                    data={{label: 'Is Beta', value: false}}
                  />
                </Grid>

                <Grid item xs={6} className={classes.flexGridItem}>
                  <DatePicker
                    name='sslAppAlertDate'
                    margin='none'
                    label='Date'
                    dateFunsUtils={DateFnsUtils}
                    format='yyyy-MM-dd'
                    disabled={!values.isSSLAppAlert}
                  />
                </Grid>
                <Grid item xs={12} className={classes.flexGridItem}>
                  <TextField
                    id='sslAppAlertText'
                    name='sslAppAlertText'
                    label='Text'
                    fullWidth
                    disabled={!values.isSSLAppAlert}
                  />
                </Grid>

                <Grid item xs={12} className={classes.flexGridItem}>
                  <Checkboxes
                    name='isSSLTrackAlert'
                    formControlProps={{margin: 'none'}}
                    data={{label: 'SSL Track Domain Notification', value: false}}
                  />
                </Grid>
                <Grid item xs={6} className={classes.flexGridItem}>
                  <DatePicker
                    name='sslTrackAlertDate'
                    margin='none'
                    label='Date'
                    dateFunsUtils={DateFnsUtils}
                    format='yyyy-MM-dd'
                    disabled={!values.isSSLTrackAlert}
                  />
                </Grid>
                <Grid item xs={12} className={classes.flexGridItem}>
                  <TextField
                    id='sslTrackAlertText'
                    name='sslTrackAlertText'
                    label='Text'
                    fullWidth
                    disabled={!values.isSSLTrackAlert}
                  />
                </Grid>

                <Grid container alignItems='flex-start' spacing={2}>
                  <Grid item style={{marginTop: 16}}>
                    <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                      <ButtonSubmit
                        name='save'
                        onSubmit={handleSubmit}
                        isPending={submitting}
                        type='submit'
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </form>
      )}
        /> :
        null
    }
    </React.Fragment>
  );
}
