import React from 'react';
import {TextField} from 'mui-rff';
import {required} from 'utils/validate';

export const createFormFields = (values, isUpdate) => {
  return [
    {
      size: 4,
      isCopy: true,
      name: 'name',
      field: (
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          fieldProps={{validate: required}}
        />
      ),
    },
    {
      size: 12,
      field: <TextField name='note' multiline label='Notes' margin='none' />,
    },
  ];
};
