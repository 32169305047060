import Box from '@mui/material/Box';
import {Button} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function ButtonSubmit(props) {
  const {name, onSubmit, isPending, type, disabled, style = {}} = props;

  return (
    <Box sx={{position: 'relative', ...style}}>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={onSubmit}
        disabled={disabled || isPending}
        type={type}
      >
        {name}
      </Button>
      {
        isPending &&
          <CircularProgress
            size={24}
            sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
          />
      }
    </Box>
  );
}
