import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import {Button, makeStyles, createTheme} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {
  DataGrid,
} from '@mui/x-data-grid';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import Title from './Title';
import {getColumns} from './utils';
import {REMOVED} from '../../constants';
import {escapeRegExp, stringToBoolean} from 'utils/common';
import {getWhiteLabels} from '../../actions';
import {changeBiddingStatus} from '../../actions/whitelabels';
import ConfirmationDialog from 'components/common/ConfirmationDialog';

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) => ({
  root: {
    '& .status.new': {
      backgroundColor: '#f6ab2e',
    },
    '& .status.active': {
      backgroundColor: '#3ed436',
    },
    '& .status.paused': {
      backgroundColor: '#f6ab2e',
    },
    '& .status.removed': {
      backgroundColor: '#D47483',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  },
  colCell: {
    flex: 1,
    minWidth: '1px !important',
    maxWidth: 'none !important',
  },
  viewport: {
    '& .rendering-zone': {
      width: 'initial !important',
      maxWidth: 'initial !important',
    },
  },
  row: {
    width: '100% !important',
  },
  colCellWrapper: {
    display: 'flex',
  },
  cell: {
    'flex': 1,
    'minWidth': '1px !important',
    'maxWidth': 'none !important',
    '&:last-of-type': {
      minWidth: '0 !important',
      flex: 0,
    },
  },
  toolbarContainer: {
    'display': 'flex',
    'justify-content': 'space-between',
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    'margin': '5px 0 0 15px',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
    button: {
      'margin': '5px 5px 0 0',
    },
}),
  {defaultTheme},
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.toolbarContainer}>
      <TextField
        variant='standard'
        value={props.value}
        onChange={props.onChange}
        placeholder='Search…'
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize='small' />,
          endAdornment: (
            <IconButton
              title='Clear'
              aria-label='Clear'
              size='small'
              style={{visibility: props.value ? 'visible' : 'hidden'}}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize='small' />
            </IconButton>
          ),
        }}
      />

      <Grid item xs={2} md={4} lg={4} align='left'>
        <div className='actions_cover active'>
          <div className='actions_cover-item'>
            <span onClick={props.onOpenBiddingStatusDialog} data-is-stop-bidding='false' data-data-center='us'>Enable US</span>
          </div>
          <div className='actions_cover-item'>
            <span onClick={props.onOpenBiddingStatusDialog} data-is-stop-bidding='true' data-data-center='us'>Disable US</span>
          </div>
          <div className='actions_cover-item'>
            <span onClick={props.onOpenBiddingStatusDialog} data-is-stop-bidding='false' data-data-center='eu'>Enable EU</span>
          </div>
          <div className='actions_cover-item'>
            <span onClick={props.onOpenBiddingStatusDialog} data-is-stop-bidding='true' data-data-center='eu'>Disable EU</span>
          </div>
        </div>
      </Grid>

      <Grid item xs={4} md={6} lg={6} align='right'>
        <Button
          variant='contained'
          color='primary'
          onClick={props.handleCreateWl}
          className={classes.button}
        >
          Add New
        </Button>
      </Grid>
    </div>
  );
}

export default function DataTable(props) {
  const {history} = props;
  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();

  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isStopBidding, setStopBidding] = useState(null);
  const [dataCenter, setDataCenter] = useState('');
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (!searchValue) {
      setRows(data.filter((item) => item.status !== REMOVED));
      return;
    }

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getWhiteLabels();
      setData(data);
      setRows(data.filter((item) => item.status !== REMOVED));
    };
    fetchData();
  }, []);

  const handleCreateWl = () => {
    history.push(`/create`);
  };

  const handleEditWl = (props, row) => {
    history.push(`/update/${row.id}`);
  };

  const onCloseCompleteDialog = (event) => {
    const {isStopBidding, dataCenter} = event.currentTarget.dataset;
    const isStopBiddingToBool = stringToBoolean(isStopBidding);

    if (!selectedRows.length) {
      const biddingStatus = isStopBidding ? 'DISABLE' : 'ENABLE';

      enqueueSnackbar(`Select clients to ${biddingStatus} bidding `, {variant: 'warning'});
      return;
    }

    setShowCompleteDialog(!showCompleteDialog);
    setStopBidding(isStopBiddingToBool);
    setDataCenter(dataCenter);
  };

  const onHandleChangeBiddingStatus = async () => {
    if (!selectedRows.length) {
      return;
    }

    await changeBiddingStatus(selectedRows, isStopBidding, dataCenter);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={4} md={6} lg={6}>
          <Title>Platforms</Title>
        </Grid>
      </Grid>

      <ConfirmationDialog
        title='Platforms bidding'
        contentText='By clicking "Approve", you confirm changing bidding status'
        isOpen={showCompleteDialog}
        handleConfirm={onHandleChangeBiddingStatus}
        handleClose={onCloseCompleteDialog}
      />

      <div
        className={`${classes.colCell} ${classes.viewport} ${classes.row} ${classes.colCellWrapper} ${classes.cell}`}
        style={{width: '100%', marginTop: '5px'}}
      >
        <DataGrid
          rows={rows || []}
          hasFocus={false}
          columns={getColumns(props)}
          checkboxSelection
          disableSelectionOnClick
          disableColumnMenu
          hideFooterPagination
          className={`${classes.root}`}
          onCellClick={(params) => {
            if (params.field === 'platformName') {
              handleEditWl(props, params.row);
            }
          }}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          getRowClassName={(params) => params.row.status === 'removed' ? 'disabled' : ''}
          sortModel={[{field: 'wlid', sort: 'desc'}]}
          components={{Toolbar: QuickSearchToolbar}}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
              handleCreateWl: handleCreateWl,
              onOpenBiddingStatusDialog: onCloseCompleteDialog,
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}
