import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import Slide from '@material-ui/core/Slide';
import {SnackbarProvider} from 'notistack';

import App from './components/app';

import configureStore from './store';
// Create redux store with history
const store = configureStore();

import '../assets/style.scss';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
        TransitionComponent={Slide}
      >
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
