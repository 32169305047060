import moment from 'moment';
import _ from 'lodash';
import Hashids from 'hashids';
import {NotificationManager} from 'react-notifications';

import {getWhiteLabels} from '../actions';
import config from '../config';

export const fetchWhiteLabels = async () => {
  const data = await getWhiteLabels();
};

export const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const showSuccessNotification = (text) => NotificationManager.success(text);
export const showErrorNotification = (text) => NotificationManager.error(text);
export const showWarningNotification = (text) => NotificationManager.warning(text);

const {salt, ln, alphabet} = config.hashIds;
export const hashids = new Hashids(salt, ln, alphabet);

export const stringToBoolean = (stringValue) => {
  if (_.isBoolean(stringValue) || !_.isString(stringValue)) {
    return stringValue;
  }

  switch (stringValue.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;

    case 'false':
    case 'no':
    case '0':
    case 'null':
    case '':
    case 'undefined':
      return false;

    default:
      return JSON.parse(stringValue);
  }
};

export const randomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
};

export const getStartDate = () => moment(new Date()).add(-6, 'days').format('YYYY-MM-DD');
export const getEndDate = () => moment(new Date()).add(0, 'days').format('YYYY-MM-DD');
