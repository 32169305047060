import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Title from 'components/common/Title';
import {Grid, Paper} from '@material-ui/core';
import {DataGrid} from '@mui/x-data-grid';
import {useSnackbar} from 'notistack';
//
import FilterPanel from './FilterPanel';
import Loader from 'components/common/Loader';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import {DoneIcon} from 'components/common/icons';
import {UGW_TRANSACTIONS_HISTORY_COLUMN_DEFS} from 'utils/tables/columns/transactionsHistory';
import {approvePayment, completePayment, getPublisherPaymentTransactions} from 'actions/index';
import useStyles from '../transactions.styles';
import PaymentDetails from './PaymentDetails';

function QuickSearchToolbar(props) {
    if (!props.isRowsSelected) {
      return null;
    }

    return (
      <Grid item xs={4} md={6} lg={6} align='left'>
        <div className='actions_cover active'>
          <div className='actions_cover-item' onClick={() => props.onOpenApprovePaymentDialog('APPROVE')} data-id='ACTIVE'>
            <DoneIcon style={{marginBottom: '-7px'}} />
            <span>Approve</span>
          </div>
          <div className='actions_cover-item' onClick={() => props.onOpenCompletePaymentDialog('COMPLETE')} data-id='ACTIVE'>
            <DoneIcon style={{marginBottom: '-7px'}} />
            <span>Create Payment</span>
          </div>
        </div>
      </Grid>
    );
}

function NoRowsOverlay() {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center'>
      No rows in DataGrid
    </Stack>
  );
}

const TransactionHistoryTable = (props) => {
    // hooks
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [transactions, setTransactions] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showCompleteDialog, setShowCompleteDialog] = useState(false);
    const [showPaymentDetailsDialog, setShowPaymentDetailsDialog] = useState(false);
    const [isPending, setPending] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [selectedPayments, setSelectedPayments] = useState([]);

    useEffect(() => {
      const fetchTransactionsData = async () => {
        const transactionList = await getPublisherPaymentTransactions();
        setTransactions(transactionList);
      };

      fetchTransactionsData();
    }, []);

    const applyFilters = async (filters) => {
      const results = await getPublisherPaymentTransactions(filters);
      setTransactions(results);
    };

    const onToggleOpenDialog = (eventType) => {
      let wrongTypeSelectedPayments = [];

      if (eventType === 'APPROVE') {
        wrongTypeSelectedPayments = selectedPayments.filter((row) => row.status !== 'PENDING');

        if (wrongTypeSelectedPayments.length) {
          enqueueSnackbar('Only Payments in status PENDING can be approved', {variant: 'warning'});
          return;
        }
        setShowDialog(!showDialog);
      } else if (eventType === 'COMPLETE') {
        wrongTypeSelectedPayments = selectedPayments.filter((row) => row.status !== 'APPROVED');
        if (wrongTypeSelectedPayments.length) {
          enqueueSnackbar('Only Payments in status APPROVED can be completed', {variant: 'warning'});
          return;
        }
        setShowCompleteDialog(!showCompleteDialog);
      }
    };

    const onCloseApproveDialog = () => setShowDialog(!showDialog);

    const onCloseCompleteDialog = () => setShowCompleteDialog(!showCompleteDialog);

    const onToggleOpenPaymentDetailsDialog = () => {
      setShowPaymentDetailsDialog(!showPaymentDetailsDialog);
      setSelectedPayment(null);
    };

    const handleApprovePayment = async () => {
      setShowDialog(false);
      setPending(true);

      await approvePayment(selectedRows);
      window.location.href = '/transactions/publishers';
    };

    const handleCompletePayment = async () => {
      setShowCompleteDialog(false);
      setPending(true);

      await completePayment(selectedRows);
      window.location.href = '/transactions/publishers';
    };

    return (
      <Paper>
        <div style={{width: '100%', marginTop: '15px', marginBottom: '15px'}}>
          <Box sx={{pt: 2, px: 2}}>
            <Title>Publishers Transaction History</Title>
          </Box>
          <Box>
            <FilterPanel
              clientOptionsData={props.clientOptionsData}
              applyFilters={applyFilters}
            />
          </Box>
          <Box sx={{pt: 2, px: 2, paddingBottom: '15px'}}>
            <Loader isPending={isPending} />
            <ConfirmationDialog
              title='Publisher Payment'
              contentText='By clicking "Approve", you confirm that payment amount is correct.'
              isOpen={showDialog}
              handleConfirm={handleApprovePayment}
              handleClose={onCloseApproveDialog}
            />
            <ConfirmationDialog
              title='Publisher Payment - Complete'
              contentText='By clicking "Approve", you confirm that you have transferred money to the Publisher and complete payment.'
              isOpen={showCompleteDialog}
              handleConfirm={handleCompletePayment}
              handleClose={onCloseCompleteDialog}
            />
            {
              selectedPayment ? (
                <PaymentDetails
                  isOpen={showPaymentDetailsDialog}
                  handleClose={onToggleOpenPaymentDetailsDialog}
                  payment={selectedPayment}
                />
              ) : null
            }
            <DataGrid
              rows={transactions}
              columns={UGW_TRANSACTIONS_HISTORY_COLUMN_DEFS}
              className={`${classes.root}`}
              sx={{
                'boxShadow': 2,
                'border': 0.5,
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                'height': 'auto',
              }}
              components={{Toolbar: QuickSearchToolbar, NoRowsOverlay}}
              componentsProps={{
                toolbar: {
                  onOpenApprovePaymentDialog: onToggleOpenDialog,
                  onOpenCompletePaymentDialog: onToggleOpenDialog,
                  isRowsSelected: selectedRows.length > 0,
                },
              }}
              onCellClick={(params) => {
                if (params.field === 'paymentNumber') {
                  setShowPaymentDetailsDialog(true);
                  setSelectedPayment(params.row);
                }
              }}
              onRowSelectionModelChange={(ids) => {
                const selectedIds = new Set(ids);
                const filtered = transactions.filter((row) =>
                  selectedIds.has(row.id),
                );
                const selectedRows = filtered.map((row) => row.paymentNumber);
                setSelectedRows(selectedRows);
                setSelectedPayments(filtered);
              }}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </Paper>
    );
};

export default TransactionHistoryTable;
