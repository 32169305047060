import {
  Grid,
  makeStyles,
  Paper,
  TextareaAutosize, Switch, FormControlLabel,
} from '@material-ui/core';
import {Form, Field} from 'react-final-form';
import {TextField} from 'mui-rff';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React, {useEffect} from 'react';
import Box from '@mui/material/Box';

import {
  getTraffDomainByID,
  updateTraffDomainByID,
} from 'actions/index';
import {reloadCDNNginx, reloadTrackNginx} from 'actions/reload';
import ButtonSubmit from 'components/common/ButtonSubmit';
import {showErrorNotification, showSuccessNotification} from 'utils/common';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: '100%',
  },
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function TraffDomain(props) {
  const {isUpdate, match} = props;
  const classes = useStyles();
  const [traffDomainInfo, setTraffDomainInfo] = React.useState(null);
  const [isPending, setIsPending] = React.useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (isUpdate) {
        const data = await getTraffDomainByID(match.params.id);
        if (data) {
          setTraffDomainInfo(data.traffDomains[0] || {});
        }
      }
    };
    fetchData();
  }, []);

  const onReload = async (values, node, isFirstNginx) => {
    setIsPending((prevState) => ({
      ...prevState,
      [node]: true,
    }));
    const obj = {
      domain: values.domain,
      isHTTPS: values.isHTTPS,
      sslCert: values.sslCert,
      sslKey: values.sslKey,
      isFirstNginx,
    };
    const result = await reloadTrackNginx(obj);
    if (result.status === 200) {
      showSuccessNotification('Success!');
    } else {
      showErrorNotification(`Server error: ${result.message}`);
    }
    setIsPending((prevState) => ({
      ...prevState,
      [node]: false,
    }));
  };

  const onSubmit = async (values) => {
    try {
      await updateTraffDomainByID(match.params.id, values);
      showSuccessNotification('Success!');
    } catch (e) {
      showErrorNotification('Server error');
    }
  };

  return (
    <React.Fragment>
      {
        traffDomainInfo ?
          <Form
            onSubmit={(values) => onSubmit(values, {...props})}
            initialValues={traffDomainInfo}
            render={({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate>
                <Paper style={{padding: 16}}>
                  <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item xs={6} className={classes.flexGridItem}>
                      <TextField
                        name='domain'
                        label='Domain'
                        margin='none'
                        placeholder='domain.com'
                      />
                      <CopyToClipboard
                        text={values.domain}
                        onCopy={() => showSuccessNotification('Copied!')}
                      >
                        <IconButton
                          size='small'
                          color='primary'
                          aria-label='copy'
                        >
                          <FileCopyOutlinedIcon fontSize='small' />
                        </IconButton>
                      </CopyToClipboard>
                    </Grid>

                    <Grid item xs={6} className={classes.flexGridItem}>
                      <TextField
                        name='cname'
                        label='Cname'
                        margin='none'
                      />
                      <CopyToClipboard
                        text={values.cname}
                        onCopy={() => showSuccessNotification('Copied!')}
                      >
                        <IconButton
                          size='small'
                          color='primary'
                          aria-label='copy'
                        >
                          <FileCopyOutlinedIcon fontSize='small' />
                        </IconButton>
                      </CopyToClipboard>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='isHTTPS'
                        component={(props) => (
                          <FormControlLabel
                            control={
                              <Switch
                                name={props.input.name}
                                checked={props.input.value}
                                onChange={props.input.onChange}
                              />
                            }
                            label='https'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='sslCert'
                        component={(props) => (
                          <TextareaAutosize
                            disabled={!values.isHTTPS}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            aria-label='minimum height'
                            minRows={10}
                            placeholder='Cert'
                            style={{width: '100%', height: 50}}
                          />)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        name='sslKey'
                        component={(props) => (
                          <TextareaAutosize
                            disabled={!values.isHTTPS}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            aria-label='minimum height'
                            minRows={10}
                            placeholder='Key'
                            style={{width: '100%', height: 50}}
                          />)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex'}}>
                          <ButtonSubmit
                            name='r1'
                            onSubmit={() => onReload(values, `b1`, true)}
                            isPending={isPending[`b1`]}
                          />
                          <ButtonSubmit
                            name='r2'
                            onSubmit={() => onReload(values, `b2`, false)}
                            isPending={isPending[`b2`]}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item style={{marginTop: 16}}>
                      <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <ButtonSubmit
                          name='save'
                          onSubmit={handleSubmit}
                          isPending={submitting}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          /> :
          null
      }
    </React.Fragment>
  );
}
