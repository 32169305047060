const config = {
  subDomain: 'http://ui-{TITLE}.ubidex.bid',
  usaTraffDomain: 'http://us-rtb.ugateway.xyz',
  gatewayApiDomain: 'http://stats.ugateway.xyz',
  // usaTraffDomain: 'http://rtb-gateway.ubidex.xyz',
  euTraffDomain: 'http://eu-rtb.ugateway.xyz',
  // euTraffDomain: 'http://eu-rtb-gateway.ubidex.xyz',

  hashIds: {
    salt: 'QrS6CUSpQgze5ufW',
    ln: 6,
    alphabet: 'abcdefghjkmnpqrstuvwxyz123456789',
  },
};

export default config;
