import React from 'react';
import {Grid, Container, makeStyles} from '@material-ui/core';
//
import General from 'components/form/publisher/tabs/General';
import Title from 'components/common/Title';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const CreatePublisher = (props) => {
    const classes = useStyles();

    return (
      <Container maxWidth='95%' className={classes.container}>
        <div className={classes.appBarSpacer} />
        <Grid container spacing={3}>
          <Grid item xs={4} md={6} lg={6}>
            <Title>Publishers Info</Title>
          </Grid>
        </Grid>
        <General isUpdate={props.isUpdate} match={props.match} history={props.history} />
      </Container>
    );
};

export default CreatePublisher;
