import * as React from 'react';
import {CssBaseline} from '@material-ui/core';
import General from './tabs/General';

export default function PublisherEditPage(props) {
  return (
    <div style={{paddingTop: 64, margin: 'auto', maxWidth: '1200px'}}>
      <CssBaseline />
      <br />
      <General isUpdate={props.isUpdate} match={props.match} history={props.history} />
    </div>
  );
}
