import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import ButtonSubmit from 'components/common/ButtonSubmit';

const ConfirmationDialog = ({title, contentText, isOpen, handleConfirm, handleClose}) => {
  const [isPending, setPending] = useState(false);

  const onApprove = () => {
    setPending(true);
    handleConfirm();
  };

  return (
    <Box sx={{width: '400px', height: '20%'}}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ButtonSubmit
            type='button'
            name='Approve'
            onSubmit={onApprove}
            isPending={isPending}
            style={{marginLeft: '10px'}}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConfirmationDialog;
