import React, {Fragment, useState, useEffect} from 'react';
import {Container, makeStyles} from '@material-ui/core';
//
import TransactionHistoryTable from './TransactionHistoryTable';
import useStyles from '../transactions.styles';
import {getPublisherDropdownValues} from 'actions/reports';

const GatewayTransactions = (props) => {
    // hooks
    const classes = useStyles();

    const [ugwPublisherOptions, setUgwPublisherOptions] = useState([]);

    useEffect(() => {
      if (props.user) {
        const fetchData = async () => {
          const publishers = await getPublisherDropdownValues();
          setUgwPublisherOptions(publishers);
        };

        fetchData();
      }
    }, [props.user]);

    return (
      <Fragment>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='95%' className={classes.container}>
          <TransactionHistoryTable clientOptionsData={ugwPublisherOptions} />
        </Container>
      </Fragment>
    );
};
export default GatewayTransactions;
