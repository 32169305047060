import React, {Fragment, useEffect, useState} from 'react';
import {Container} from '@material-ui/core';
//
import TransactionHistoryTable from './TransactionHistoryTable';
import {getWlIdDropdownValues} from 'actions/reports';
import useStyles from '../transactions.styles';

const ClientTransactions = (props) => {
    // hooks
    const classes = useStyles();

    const [wlidOptions, setWlidOptions] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const wlids = await getWlIdDropdownValues();
        setWlidOptions(wlids);
      };

      fetchData();
    }, []);

    return (
      <Fragment>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='95%' className={classes.container}>
          <TransactionHistoryTable
            clientOptionsData={wlidOptions}
          />
        </Container>
      </Fragment>
    );
};
export default ClientTransactions;
