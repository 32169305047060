import {useSnackbar} from 'notistack';
import React, {Fragment, useEffect} from 'react';
import {Grid, makeStyles, MenuItem, Paper} from '@material-ui/core';
import {Form} from 'react-final-form';
import {Checkboxes, DatePicker, Select, TextField} from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';

import ButtonSubmit from 'components/common/ButtonSubmit';

import {getGeneralByID, updateWhiteLabel} from 'actions/index';
import {isFloat, isInteger, validateSubscriptionTab} from 'utils/validate';
import {PAYMENT_ALERT} from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const initial = {
    paymentAlert: PAYMENT_ALERT,
};

export default function Subscription(props) {
    const {isUpdate, match} = props;
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [generalInfo, setGeneralInfo] = React.useState(null);

    useEffect(() => {
      const fetchData = async () => {
        if (isUpdate) {
          const data = await getGeneralByID(match.params.id);
          setGeneralInfo(data);
        } else {
          setGeneralInfo(initial);
        }
      };

      fetchData();
    }, []);

    const onSubmit = async (values) => {
      try {
        await updateWhiteLabel(values);

        enqueueSnackbar('Success!', {variant: 'success'});
      } catch (e) {
        enqueueSnackbar('Server error!', {variant: 'error'});
      }
    };

    return (
      <Fragment>
        {
          generalInfo ?
            <Form
              onSubmit={(values) => onSubmit(values, {...props})}
              initialValues={generalInfo}
              validate={validateSubscriptionTab}
              render={({handleSubmit, form, submitting, pristine, values}) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Paper style={{padding: 16}}>
                    <Grid container alignItems='flex-start' spacing={2}>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        <Select
                          name='subscription'
                          label='Subscription'
                          multiple={false}
                        >
                          <MenuItem value='STARTER'>Starter</MenuItem>
                          <MenuItem value='ADVANCED'>Advanced</MenuItem>
                          <MenuItem value='PREMIUM'>Premium</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        <TextField
                          id='qpsPlan'
                          name='qpsPlan'
                          label='Qps'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        <TextField
                          id='pricePlan'
                          name='pricePlan'
                          label='Price'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        {isUpdate ?
                          <Select
                            name='paymentStatus'
                            label='Payment Status'
                            formControlProps={{margin: 'none'}}
                            multiple={false}
                          >
                            <MenuItem value='PAID'>Paid</MenuItem>
                            <MenuItem value='UNPAID'>Unpaid</MenuItem>
                          </Select> : null}
                      </Grid>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        {isUpdate ?
                          <TextField
                            id='maxBrandNumbers'
                            name='maxBrandNumbers'
                            label='Max Number of Brands'
                            fieldProps={{validate: isInteger}}
                            fullWidth
                          /> : null}
                      </Grid>
                      <Grid item xs={3} className={classes.flexGridItem}>
                        {isUpdate ?
                          <TextField
                            id='maxBidCPM'
                            name='maxBidCPM'
                            label='Max Bid CPM'
                            fieldProps={{validate: isFloat}}
                            fullWidth
                          /> : null}
                      </Grid>
                      <Grid item xs={3} className={classes.flexGridItem}>
                        {isUpdate ?
                          <TextField
                            id='maxBidCPC'
                            name='maxBidCPC'
                            label='Max Bid CPC'
                            fieldProps={{validate: isFloat}}
                            fullWidth
                          /> : null}
                      </Grid>
                      <Grid item xs={12} className={classes.flexGridItem}>
                        <Checkboxes
                          name='isDsp'
                          formControlProps={{margin: 'none'}}
                          data={{label: 'DSP', value: false}}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.flexGridItem}>
                        <Checkboxes
                          name='isUbidexGatewayEnable'
                          formControlProps={{margin: 'none'}}
                          data={{label: 'Ubidex Gateway', value: false}}
                        />
                      </Grid>

                      <Grid item xs={12} className={classes.flexGridItem}>
                        <Checkboxes
                          label='Functionality'
                          name='functionality'
                          data={[
                            {label: 'AdExchange', value: 'AD_EXCHANGE'},
                            {label: 'Audiences', value: 'AUDIENCES'},
                            {label: 'Audience Analytics', value: 'AUDIENCE_ANALYTICS'},
                            {label: 'Pixels', value: 'PIXELS'},
                            {label: 'AdServer', value: 'AD_SERVER'},
                            {label: 'Performance', value: 'PERFORMANCE'},
                            {label: 'Billing', value: 'BILLING'},
                            {label: 'Payment Gateway', value: 'PAYMENT_GATEWAY'},
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        <Select
                          name='invoiceType'
                          label='Invoice Type'
                          formControlProps={{margin: 'none'}}
                          multiple={false}
                        >
                          <MenuItem value='PE'>PE Oleg</MenuItem>
                          <MenuItem value='INTERNAL'>Ubidex - USA Internal Transaction</MenuItem>
                          <MenuItem value='INTERNATIONAL'>Ubidex - International Transaction</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} className={classes.flexGridItem}>
                        <Checkboxes
                          name='isPaymentAlert'
                          formControlProps={{margin: 'none'}}
                          data={{label: 'Payment notification', value: false}}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.flexGridItem}>
                        <DatePicker
                          name='alertDate'
                          margin='none'
                          label='Date'
                          dateFunsUtils={DateFnsUtils}
                          format='yyyy-MM-dd'
                          disabled={!values.isPaymentAlert}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.flexGridItem}>
                        <TextField
                          id='paymentAlert'
                          name='paymentAlert'
                          label='Text'
                          fullWidth
                          disabled={!values.isPaymentAlert}
                        />
                      </Grid>
                    </Grid>
                    <Grid item alignItems='flex-start' spacing={2} style={{marginTop: 32}}>
                      <ButtonSubmit
                        name='save'
                        onSubmit={handleSubmit}
                        isPending={submitting}
                        type='submit'
                      />
                    </Grid>
                  </Paper>
                </form>
              )}
            /> : null
        }
      </Fragment>
    );
}
