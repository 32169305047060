import React, {useState, useEffect} from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
//
import Loader from 'components/common/Loader';
import {CloseIcon} from 'components/common/icons';
import {PAYMENT_TRANSACTIONS_COLUMN_DEFS} from 'utils/tables/columns/transactionsHistory';
import {getPaymentDetails} from 'actions/index';

import useStyles from '../transactions.styles';

const PaymentDetails = (props) => {
    // hooks
  const classes = useStyles();
  const [isPending, setPending] = useState(true);
  const [paymentTransactions, setPaymentTransactions] = useState([]);

  useEffect( () => {
    if (props.payment) {
      fetchData(props.payment.paymentNumber);
    }
  }, [props.payment]);

  const fetchData = async (paymentNumber) => {
    const transactions = await getPaymentDetails(paymentNumber);
    setPaymentTransactions(transactions);
    setPending(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          'minWidth': '1100px',
        },
      }}
      scroll='paper'
    >
      <Loader isPending={isPending} />
      <DialogTitle id='alert-dialog-title'>
        Payment: #{props.payment.paymentNumber}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={props.handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <DialogContentText id='alert-dialog-description'>
          <DataGrid
            rows={paymentTransactions}
            columns={PAYMENT_TRANSACTIONS_COLUMN_DEFS}
            className={`${classes.root}`}
            sx={{
              'boxShadow': 2,
              'border': 0.5,
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              'minHeight': '200px',
            }}
            checkboxSelection={false}
            hideFooter
            hideFooterPagination
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDetails;
