import Container from '@material-ui/core/Container/Container';
import React from 'react';
import {makeStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Link from '@material-ui/core/Link/Link';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    textAlign: 'center',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary'>
      {'Copyright © '}
      <Link color='inherit' target='_blank' href='https://pragmaspace.com' rel='noreferrer'>
        Ubidex
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth='sm'>
        {/* <Typography variant="body1">My sticky footer can be found here.</Typography>*/}
        <Copyright />
      </Container>
    </footer>
  );
}
