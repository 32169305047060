export const validate = (values) => {
  const errors = {};

  if (!values.platformName) {
    errors.platformName = 'Required';
  }

  return errors;
};

export const validateSubscriptionTab = (values) => {
  const errors = {};

  if (!values.invoiceType) {
    errors.invoiceType = 'Required';
  }

  if (values.id && !values.paymentStatus) {
    errors.paymentStatus = 'Required';
  }

  return errors;
};

/**
 * Use this function if you need multiple validators for one Field
*/
export const composeValidators = (...validators) => (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

/**
 * Check if `value` isn't empty
 *
 * @returns Returns `undefined` if `value` isn't empty, else `error message`.
 */
export const required = (value) => {
  if (value === undefined || value === null) {
    return 'Required';
  }
  if (typeof value === 'string' && value.trim() === '') {
    return 'Required';
  }
  return undefined;
};

/**
 * Check if `value` is number and greater than 0
 *
 * @param value
 * @returns Returns `undefined` if `value` is `number`, else `error message`.
 */
export const isNumber = (value) => !value || /^\d*\.?\d{0,2}$/.test(value) ? undefined : 'Invalid number value';

export const isNumberAndNegative = (value) => !value || /^-?\d*\.?\d{0,2}$/.test(value) ? undefined : 'Invalid number value';

/**
 * Check if `value` is Integer and greater than 0
 *
 * @param value
 * @returns Returns `undefined` if `value` is `Integer`, else `error message`.
 */
export const isInteger = (value) =>
    !value || /^\d*[0-9]\d*$/.test(value) ?
        undefined :
        'Invalid input value. Only positive integers are allowed';

export const isFloat = (value) =>
    !value || /^\d*(\.\d+)?$/.test(value) ?
        undefined :
        'Invalid input value. Only positive float numbers are allowed';

/**
 * Check if `value` is not greater than limit
 *
 * @returns Returns `undefined` if `value` is more or equal than limit, else `error message`.
 */
export const minValue = (limit) => (value) => (!value || value >= limit) ? undefined : `Can't be less than ${limit}`;


/**
 * Check if `value` is greater than limit
 *
 * @returns Returns `undefined` if `value` is more or equal than limit, else `error message`.
 */
export const maxValue = (limit) => (value) => (!value || value <= limit) ? undefined : `Can't be more than ${limit}`;

export const isUrl = (value) => {
  try {
    if (value) {
      new URL(value);
    }
    return undefined;
  } catch (err) {
    return 'Not valid url string';
  }
};

