import React, {useEffect} from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextareaAutosize, Switch, FormControlLabel,
} from '@material-ui/core';
import {Form, Field} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {TextField} from 'mui-rff';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import {NEW, PAYMENT_ALERT} from '../../../../constants';
import {getTrackDomainsByID, updateTrackDomainsByID} from 'actions/index';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import {reloadTrackNginx} from 'actions/reload';
import ButtonSubmit from 'components/common/ButtonSubmit';
import {showErrorNotification, showSuccessNotification} from 'utils/common';

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: '100%',
  },
  flexGridItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function TrackDomain(props) {
  const {isUpdate, match} = props;
  const classes = useStyles();
  const [initialData, setInitialData] = React.useState(null);
  const [isPending, setIsPending] = React.useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (isUpdate) {
        const data = await getTrackDomainsByID(match.params.id);
        setInitialData(data);
      }
    };
   fetchData();
  }, []);

  const onReload = async (item, index, isFirstNginx) => {
    setIsPending((prevState) => ({
      ...prevState,
        [index]: true,
    }));
    const obj = {
      wlid: item.wlid,
      domain: item.trackDomain,
      isHTTPS: item.isHTTPS,
      sslCert: item.sslCert,
      sslKey: item.sslKey,
      isFirstNginx,
    };
    const result = await reloadTrackNginx(obj);
    if (result.status === 200) {
      showSuccessNotification('Success!');
    } else {
      showErrorNotification(`Server error: ${result.message}`);
    }
    setIsPending((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const onSubmit = async (values, props) => {
    try {
      const {isUpdate, match} = props;
      if (isUpdate) {
        await updateTrackDomainsByID(match.params.id, values);
      }
      showSuccessNotification('Success!');
    } catch (e) {
      showErrorNotification('Server error');
    }
  };

  return (
    <React.Fragment>
      {
        initialData ?
          <Form
            onSubmit={(values) => onSubmit(values, {...props})}
            mutators={{...arrayMutators}}
            initialValues={initialData}
            // validate={validate}
            render={({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate>
                <FieldArray name='trackDomains'>
                  {({fields}) => (
                    <div>
                      {
                        fields.map((item, index) => (
                          <Paper style={{padding: 16, marginBottom: 16}} key={index}>
                            <Grid container alignItems='flex-start' spacing={2}>
                              <Grid item xs={6} className={classes.flexGridItem}>
                                <TextField
                                  name={`${item}.trackDomain`}
                                  multiline
                                  label='Domain'
                                  margin='none'
                                  placeholder='track.domain.com'
                                />
                                <CopyToClipboard
                                  text={fields.value[index].trackDomain}
                                  onCopy={() => showSuccessNotification('Copied!')}
                                >
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    aria-label='copy'
                                  >
                                    <FileCopyOutlinedIcon fontSize='small' />
                                  </IconButton>
                                </CopyToClipboard>
                              </Grid>
                              <Grid item xs={6} className={classes.flexGridItem} />
                              <Grid item xs={6} className={classes.flexGridItem}>
                                <TextField
                                  name={`${item}.nginx1Ip`}
                                  label='Nginx1'
                                  margin='none'
                                  placeholder='192.168.1.1'
                                />
                                <CopyToClipboard
                                  text={fields.value[index].nginx1Ip}
                                  onCopy={() => showSuccessNotification('Copied!')}
                                >
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    aria-label='copy'
                                  >
                                    <FileCopyOutlinedIcon fontSize='small' />
                                  </IconButton>
                                </CopyToClipboard>
                              </Grid>
                              <Grid item xs={6} className={classes.flexGridItem}>
                                <TextField
                                  name={`${item}.nginx2Ip`}
                                  label='Nginx2'
                                  margin='none'
                                  placeholder='192.168.1.2'
                                />
                                <CopyToClipboard
                                  text={fields.value[index].nginx2Ip}
                                  onCopy={() => showSuccessNotification('Copied!')}
                                >
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    aria-label='copy'
                                  >
                                    <FileCopyOutlinedIcon fontSize='small' />
                                  </IconButton>
                                </CopyToClipboard>
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name={`${item}.isHTTPS`}
                                  component={(props) => (
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name={props.input.name}
                                          checked={props.input.value}
                                          onChange={props.input.onChange}
                                        />
                                        }
                                      label='https'
                                    />
                                    )}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name={`${item}.sslCert`}
                                  component={(props) => (
                                    <TextareaAutosize
                                      name={props.input.name}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                      aria-label='minimum height'
                                      minRows={10}
                                      placeholder='Cert'
                                      style={{width: '100%', height: 50}}
                                    />)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name={`${item}.sslKey`}
                                  component={(props) => (
                                    <TextareaAutosize
                                      name={props.input.name}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                      aria-label='minimum height'
                                      minRows={10}
                                      placeholder='Key'
                                      style={{width: '100%', height: 50}}
                                    />)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                  <Box sx={{display: 'flex'}}>
                                    <ButtonSubmit
                                      name='r1'
                                      onSubmit={() => onReload(values.trackDomains[index], `b1-${index}`, true)}
                                      isPending={isPending[`b1-${index}`]}
                                    />
                                    <ButtonSubmit
                                      name='r2'
                                      onSubmit={() => onReload(values.trackDomains[index], `b2-${index}`, false)}
                                      isPending={isPending[`b2-${index}`]}
                                    />
                                  </Box>
                                  <IconButton
                                    aria-label='delete'
                                    color='secondary'
                                    onClick={() => fields.remove(index)}
                                  >
                                    <DeleteIcon fontSize='inherit' />
                                  </IconButton>
                                </Box>
                              </Grid>
                            </Grid>
                          </Paper>
                          ),
                        )
                      }
                      <Grid container alignItems='flex-start' spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            fullWidth
                            onClick={() => fields.push({})}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </div>

                  )}
                </FieldArray>

                <Grid container alignItems='flex-start' spacing={2}>
                  <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item style={{margin: '16px 0 0 10px'}}>
                      <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <ButtonSubmit
                          name='save'
                          onSubmit={handleSubmit}
                          isPending={submitting}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          /> :
          null
      }
    </React.Fragment>
  );
}
